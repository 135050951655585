import { ICampaign } from "../../dtos/campaigns";
import { campaignActions } from "./types";

export interface ICampaignsReducer {
    campaigns: ICampaign[],
    isLoading: boolean,
    totalCount: number,

}
const initialState: ICampaignsReducer = {
    campaigns: [],
    totalCount: 0,
    isLoading: false
}
export default function campaignReducer(state: ICampaignsReducer = initialState, action: campaignActions) {
    switch (action.type) {
        case "GET_CAMPAIGNS":
            return {
                ...state,
                campaigns: action.payload.campaigns,
                totalCount: action.payload.totalCount,
            };
        case "UPDATE_IS_LOADING_CAMPAIGNS":
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state;
    }
}