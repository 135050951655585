import { IUpdateIsLoadingOrdersAction, OrderActions } from "./types";
import {
  LOAD_NF_ORDERS,
  LOAD_NF_ORDER_LINES,
  LOAD_OPEN_ORDERS,
  LOAD_ORDERS,
  LOAD_ORDERS_TRACKING,
  LOAD_ORDER_HISTORY,
  LOAD_READY_PICKING,
  UPDATE_IS_LOADING_ORDERS,
} from "../../sagas/orders/types";
import { IOrder } from "../../dtos/IOrder";
interface IOrderStatus {
  orders: IOrder[];
  openOrders: IOrder[];
  readyPicking: IOrder[];
  NFOrders: IOrder[];
  NFOrderLines: IOrder[];
  orderHistory: any[];
  totalOrderHistory: number;
  totalOrdersCount: number;
  totalOpenOrdersCount: number;
  totalReadyPickingCount: number;
  totalNFOrdersCount: number;
  totalNFOrderLinesCount: number;
  loadingOrders: IUpdateIsLoadingOrdersAction["payload"];
}
const OrderStatus: IOrderStatus = {
  orders: [],
  openOrders: [],
  readyPicking: [],
  NFOrders: [],
  NFOrderLines: [],
  orderHistory: [],
  totalOrderHistory: 0,
  totalOrdersCount: 0,
  totalOpenOrdersCount: 0,
  totalReadyPickingCount: 0,
  totalNFOrdersCount: 0,
  totalNFOrderLinesCount: 0,
  loadingOrders: {
    isLoadingOrders: false,
    errorMessage: undefined,
  },
};
const orderReducer = (
  state = OrderStatus,
  action: OrderActions
): IOrderStatus => {
  switch (action.type) {
    case LOAD_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
        totalOrdersCount: action.payload.totalOrdersCount,
      };
    case LOAD_ORDERS_TRACKING:
      return {
        ...state,
        orders: action.payload.orders,
        totalOrdersCount: action.payload.totalOrdersCount,
      };
    case LOAD_OPEN_ORDERS:
      return {
        ...state,
        openOrders: action.payload.orders,
        totalOpenOrdersCount: action.payload.totalOrdersCount,
      };
    case LOAD_READY_PICKING:
      return {
        ...state,
        readyPicking: action.payload.orders,
        totalReadyPickingCount: action.payload.totalOrdersCount,
      };
    case LOAD_NF_ORDERS:
      return {
        ...state,
        NFOrders: action.payload.orders,
        totalNFOrdersCount: action.payload.totalOrdersCount,
      };
    case LOAD_NF_ORDER_LINES:
      return {
        ...state,
        NFOrderLines: action.payload.orders,
        totalNFOrderLinesCount: action.payload.totalOrdersCount,
      };
    case LOAD_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload.historyActions,
        totalOrderHistory: action.payload.totalHistoryActionsCount,
      };
    case UPDATE_IS_LOADING_ORDERS:
      return {
        ...state,
        loadingOrders: action.payload,
      };
    default:
      return state;
  }
};
export default orderReducer;
