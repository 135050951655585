import { put, takeLatest, call } from "redux-saga/effects";
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
  LOAD_ORDERS_SAGA,
  ILoadOrdersActionSaga,
  LOAD_OPEN_ORDERS_SAGA,
  ILoadOpenOrdersActionSaga,
  ILoadReadyPickingActionSaga,
  LOAD_READY_PICKING_SAGA,
  ILoadNFOrdersActionSaga,
  LOAD_NF_ORDERS_SAGA,
  ILoadNFOrderLinesActionSaga,
  LOAD_NF_ORDER_LINES_SAGA,
  ILoadOrderHistoryActionSaga,
  LOAD_ORDER_HISTORY_SAGA,
  ILoadOrdersWithTrackingSaga,
  LOAD_ORDERS_TRACKING_SAGA,
} from "./types";
import { fetchNFOrderLines, fetchNFOrders, fetchOrderHistory, fetchOrders, fetchOrdersByTracking } from "../../api/orders";
import {
  ILoadOrderHistoryAction,
  loadNFOrderLinesProps,
  loadNFOrdersProps,
  loadOpenOrdersProps,
  loadOrderHistoryProps,
  loadOrdersProps,
  loadOrdersWithTrackingProps,
  loadReadyPickingProps,
  updateIsLoadingOrdersProps,
} from "../../reducers/orders/types";
import { IOrder } from "../../dtos/IOrder";

function* loadOrdersData(action: ILoadOrdersActionSaga) {
  try {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: IOrder[];
      totalOrdersCount: number;
    } = yield call(
      fetchOrders,
      action.page,
      action.size,
      action.sort,
      action.orderId,
      action.firstName,
      action.lastName,
      action.email,
      action.mobile,
      action.dateFrom,
      action.dateTo,
      action.status,
      action.userId,
      action.trackingNumber,
      action.excluded,
      action.has_NFOL,
    );
    yield put(
      loadOrdersProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOrders() {
  yield takeLatest(LOAD_ORDERS_SAGA, loadOrdersData);
}


function* loadOrdersWithTrackingData(action: ILoadOrdersWithTrackingSaga) {
  try {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: IOrder[];
      totalOrdersCount: number;
    } = yield call(
      fetchOrdersByTracking,
      action.tracking_search,
      action.page,
      action.size,
      action.sort,
    );
    yield put(
      loadOrdersWithTrackingProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOrdersWithTracking() {
  yield takeLatest(LOAD_ORDERS_TRACKING_SAGA, loadOrdersWithTrackingData);
}

function* loadOrderHistoryData(action: ILoadOrderHistoryActionSaga) {
  try {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: true,
        errorMessage: undefined,
      })
    );
    const orderHistoryData: {
      historyActions: any[];
      totalHistoryActionsCount: number;
    } = yield call(
      fetchOrderHistory,
      action.orderId,
      action.page,
      action.size,
      action.sort,
    );
    yield put(
      loadOrderHistoryProps({
        historyActions: orderHistoryData.historyActions,
        totalHistoryActionsCount: orderHistoryData.totalHistoryActionsCount,
      })
    );
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOrderHistory() {
  yield takeLatest(LOAD_ORDER_HISTORY_SAGA, loadOrderHistoryData);
}

function* loadOpenOrdersData(action: ILoadOpenOrdersActionSaga) {
  try {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: IOrder[];
      totalOrdersCount: number;
    } = yield call(
      fetchOrders,
      action.page,
      action.size,
      action.sort,
      action.orderId,
      action.firstName,
      action.lastName,
      action.email,
      action.mobile,
      action.dateFrom,
      action.dateTo,
      action.status,
      action.userId,
      action.trackingNumber,
      action.excluded
    );
    yield put(
      loadOpenOrdersProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenOrders() {
  yield takeLatest(LOAD_OPEN_ORDERS_SAGA, loadOpenOrdersData);
}


function* loadNFOrdersData(action: ILoadNFOrdersActionSaga) {
  try {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: true,
        errorMessage: undefined,
      })
    );
    const NFordersData: {
      orders: IOrder[];
      totalOrdersCount: number;
    } = yield call(
      fetchNFOrders,
      action.page,
      action.size,
      action.sort,
      action.excluded
    );
    yield put(
      loadNFOrdersProps({
        orders: NFordersData.orders,
        totalOrdersCount: NFordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadNFOrders() {
  yield takeLatest(LOAD_NF_ORDERS_SAGA, loadNFOrdersData);
}

function* loadNFOrderLines(action: ILoadNFOrderLinesActionSaga) {
  try {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: true,
        errorMessage: undefined,
      })
    );
    const NForderLinesData: {
      orders: IOrder[];
      totalOrdersCount: number;
    } = yield call(
      fetchNFOrderLines,
      action.page,
      action.size,
    );
    yield put(
      loadNFOrderLinesProps({
        orders: NForderLinesData.orders,
        totalOrdersCount: NForderLinesData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadNFOrderLines() {
  yield takeLatest(LOAD_NF_ORDER_LINES_SAGA, loadNFOrderLines);
}

function* loadReadyPickingData(action: ILoadReadyPickingActionSaga) {
  try {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: IOrder[];
      totalOrdersCount: number;
    } = yield call(
      fetchOrders,
      action.page,
      action.size,
      action.sort,
      action.orderId,
      action.firstName,
      action.lastName,
      action.email,
      action.mobile,
      action.dateFrom,
      action.dateTo,
      action.status,
      action.userId,
      action.trackingNumber,
      action.excluded
    );
    yield put(
      loadReadyPickingProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingOrdersProps({
        isLoadingOrders: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchReadyPickingOrders() {
  yield takeLatest(LOAD_READY_PICKING_SAGA, loadReadyPickingData);
}

export default [
  watchLoadOrders(),
  watchLoadOrdersWithTracking(),
  watchLoadOrderHistory(),
  watchLoadOpenOrders(),
  watchReadyPickingOrders(),
  watchLoadNFOrders(),
  watchLoadNFOrderLines(),
];
