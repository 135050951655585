import IOrderAddress from "../../dtos/IOrderAddress";
import IUser from "../../dtos/IUser";
import { GET_USERS, SEARCH_USERS, SELECT_USER, RESET_ORDER_USER, GET_USER_ADDRESSES, UPDATE_IS_LOADING_USER_ADDRESSES } from "../../sagas/users/types";

export interface IGetUsersAction {
    readonly type: typeof GET_USERS,
    payload: {
        users: IUser[],
        totalUsersCount: number,
    }
}
export function getUsersProps(userStateData: IGetUsersAction['payload']): IGetUsersAction {
    return {
        type: GET_USERS,
        payload: userStateData,
    }
}

export interface ISearchUsersAction {
    readonly type: typeof SEARCH_USERS;
    payload: {
        userResults: IUser[],
    }
}
export const searchUsersProps = (payload: ISearchUsersAction['payload']): ISearchUsersAction => {
    return {
        type: SEARCH_USERS,
        payload: payload
    }
};
export interface IAddSelectedUserAction {
    readonly type: typeof SELECT_USER;
    payload: {
        selectedUser: IUser,
    }
}
export const addSelectedUserProps = (payload: IAddSelectedUserAction['payload']): IAddSelectedUserAction => {
    return {
        type: SELECT_USER,
        payload: payload
    }
};

export interface IResetOrderUserAction {
    readonly type: typeof RESET_ORDER_USER;
    payload: {
        selectedUser: null,
    }
}
export const resetOrderUserProps = (payload: IResetOrderUserAction['payload']): IResetOrderUserAction => {
    return {
        type: RESET_ORDER_USER,
        payload: payload
    }
};

export interface IGetUserAddressesAction {
    readonly type: typeof GET_USER_ADDRESSES,
    payload: {
        userAddresses: IOrderAddress[],
    }
}
export function getUserAddressesProps(payload: IGetUserAddressesAction['payload']): IGetUserAddressesAction {
    return {
        type: GET_USER_ADDRESSES,
        payload: payload,
    }
}

export interface IUpdateIsLoadingUserAddressesAction {
    readonly type: typeof UPDATE_IS_LOADING_USER_ADDRESSES;
    payload: {
        isLoadingUserAddresses: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingUserAddressesProps = (
    payload: IUpdateIsLoadingUserAddressesAction["payload"]
): IUpdateIsLoadingUserAddressesAction => {
    return {
        type: UPDATE_IS_LOADING_USER_ADDRESSES,
        payload: payload,
    };
};
export type usersActions = IGetUsersAction | ISearchUsersAction | IAddSelectedUserAction | IResetOrderUserAction
    | IGetUserAddressesAction | IUpdateIsLoadingUserAddressesAction;
