import {
  LOAD_ORDERS,
  LOAD_OPEN_ORDERS,
  UPDATE_IS_LOADING_ORDERS,
  LOAD_READY_PICKING,
  LOAD_NF_ORDERS,
  LOAD_NF_ORDER_LINES,
  LOAD_ORDER_HISTORY,
  LOAD_ORDERS_TRACKING,
} from "../../sagas/orders/types";
import IModel from "../../dtos/IModel";
import IBranch from "../../dtos/IBranch";
import IServiceAccount from "../../dtos/IServiceAccount";
import { IOrder } from "../../dtos/IOrder";

export interface ILoadOrdersAction {
  readonly type: typeof LOAD_ORDERS;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadOrdersProps = (
  payload: ILoadOrdersAction["payload"]
): ILoadOrdersAction => {
  return {
    type: LOAD_ORDERS,
    payload: payload,
  };
};
export interface ILoadOrdersWithTrackingAction {
  readonly type: typeof LOAD_ORDERS_TRACKING;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadOrdersWithTrackingProps = (
  payload: ILoadOrdersWithTrackingAction["payload"]
): ILoadOrdersWithTrackingAction => {
  return {
    type: LOAD_ORDERS_TRACKING,
    payload: payload,
  };
};

export interface ILoadOrderHistoryAction {
  readonly type: typeof LOAD_ORDER_HISTORY;
  payload: {
    historyActions: any[];
    totalHistoryActionsCount: number;
  };
}
export const loadOrderHistoryProps = (
  payload: ILoadOrderHistoryAction["payload"]
): ILoadOrderHistoryAction => {
  return {
    type: LOAD_ORDER_HISTORY,
    payload: payload,
  };
};




export interface ILoadOpenOrdersAction {
  readonly type: typeof LOAD_OPEN_ORDERS;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadOpenOrdersProps = (
  payload: ILoadOpenOrdersAction["payload"]
): ILoadOpenOrdersAction => {
  return {
    type: LOAD_OPEN_ORDERS,
    payload: payload,
  };
};


export interface ILoadNFOrdersAction {
  readonly type: typeof LOAD_NF_ORDERS;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadNFOrdersProps = (
  payload: ILoadNFOrdersAction["payload"]
): ILoadNFOrdersAction => {
  return {
    type: LOAD_NF_ORDERS,
    payload: payload,
  };
};

export interface ILoadNFOrderLinesAction {
  readonly type: typeof LOAD_NF_ORDER_LINES;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadNFOrderLinesProps = (
  payload: ILoadNFOrderLinesAction["payload"]
): ILoadNFOrderLinesAction => {
  return {
    type: LOAD_NF_ORDER_LINES,
    payload: payload,
  };
};


export interface ILoadReadyPickingAction {
  readonly type: typeof LOAD_READY_PICKING;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadReadyPickingProps = (
  payload: ILoadReadyPickingAction["payload"]
): ILoadReadyPickingAction => {
  return {
    type: LOAD_READY_PICKING,
    payload: payload,
  };
};

export interface IUpdateIsLoadingOrdersAction {
  readonly type: typeof UPDATE_IS_LOADING_ORDERS;
  payload: {
    isLoadingOrders: boolean;
    errorMessage: string | undefined;
  };
}
export const updateIsLoadingOrdersProps = (
  payload: IUpdateIsLoadingOrdersAction["payload"]
): IUpdateIsLoadingOrdersAction => {
  return {
    type: UPDATE_IS_LOADING_ORDERS,
    payload: payload,
  };
};

export type OrderActions =
  | ILoadOrdersAction
  | ILoadOrdersWithTrackingAction
  | ILoadOrderHistoryAction
  | IUpdateIsLoadingOrdersAction
  | ILoadOpenOrdersAction
  | ILoadReadyPickingAction
  | ILoadNFOrdersAction
  | ILoadNFOrderLinesAction;
