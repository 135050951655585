export const LOAD_FEATURE_VALUES = "LOAD_FEATURE_VALUES";
export const LOAD_FEATURE_VALUES_SAGA = "LOAD_FEATURE_VALUES_SAGA";

export const RESET_FEATURE_VALUES_SAGA = "RESET_FEATURE_VALUES_SAGA";
export const RESET_FEATURE_VALUES = "RESET_FEATURE_VALUES";

export const UPDATE_IS_LOADING_FEATURE_VALUES = "UPDATE_IS_LOADING_FEATURE_VALUES";

export const LOAD_FEATURES = "LOAD_FEATURES";
export const LOAD_FEATURES_SAGA = "LOAD_FEATURES_SAGA";

export const UPDATE_IS_LOADING_FEATURES = "UPDATE_IS_LOADING_FEATURES";


export interface ILoadFeatureValuesActionSaga {
    readonly type: typeof LOAD_FEATURE_VALUES_SAGA;
    features: any,
    sort: string
}
export const loadfeatureValuesPropsSaga = (features = [], sort = ""): ILoadFeatureValuesActionSaga => {
    return {
        type: LOAD_FEATURE_VALUES_SAGA,
        features: features,
        sort: sort
    }
};
export interface ILoadFeaturesActionSaga {
    readonly type: typeof LOAD_FEATURES_SAGA;
    page: number,
    size: number,
}
export const loadfeaturesPropsSaga = (page = 0, size = 25): ILoadFeaturesActionSaga => {
    return {
        type: LOAD_FEATURES_SAGA,
        page: page,
        size: size
    }
};
export interface IResetFeatureValuesActionSaga {
    readonly type: typeof RESET_FEATURE_VALUES_SAGA;
}
export const resetFeatureValuesPropsSaga = (): IResetFeatureValuesActionSaga => {
    return {
        type: RESET_FEATURE_VALUES_SAGA,
    }
};

