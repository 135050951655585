import { string } from "yargs";
import { ICamundaTask } from "../../containers/Camunda/types";

export const LOAD_TASKS = "LOAD_TASKS";
export const LOAD_TASKS_SAGA = "LOAD_TASKS_SAGA";

export const LOAD_PROCESS_TASKS = "LOAD_PROCESS_TASKS";
export const LOAD_PROCESS_TASKS_SAGA = "LOAD_PROCESS_TASKS_SAGA";

export const LOAD_CUSTOMER_TASKS = "LOAD_CUSTOMER_TASKS";
export const LOAD_CUSTOMER_TASKS_SAGA = "LOAD_CUSTOMER_TASKS_SAGA";
export const UPDATE_IS_LOADING_CUSTOMERS = "UPDATE_IS_LOADING_CUSTOMERS";

export const LOAD_PROCESSES = "LOAD_PROCESSES";
export const LOAD_PROCESSES_SAGA = "LOAD_PROCESSES_SAGA";

export const LOAD_TASK_CLAIMER = "LOAD_TASK_CLAIMER";
export const LOAD_TASK_CLAIMER_SAGA = "LOAD_TASK_CLAIMER_SAGA";

export const LOAD_CUSTOMER_PROCESS = "LOAD_CUSTOMER_PROCESS";
export const LOAD_CUSTOMER_PROCESS_SAGA = "LOAD_CUSTOMER_PROCESS_SAGA";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SAGA = "LOAD_USERS_SAGA";

export const LOAD_GROUPS = "LOAD_GROUPS";
export const LOAD_GROUPS_SAGA = "LOAD_GROUPS_SAGA";

export const UPDATE_IS_LOADING_TASKS = "UPDATE_IS_LOADING_TASKS";
export const UPDATE_IS_LOADING_GROUPS = "UPDATE_IS_LOADING_GROUPS";
export const UPDATE_IS_LOADING_USERS = "UPDATE_IS_LOADING_USERS"
export interface ILoadUsersActionSaga {
  readonly type: typeof LOAD_USERS_SAGA;
  subject_id: string;
  group_name: string;
  by_user_group: string;
  viewBy: number;
  page: number
}
export const loadUsersPropsSaga = (
  subject_id = "",
  group_name = "",
  by_user_group = "",
  viewBy = 25,
  page = 1,
): ILoadUsersActionSaga => {
  return {
    type: LOAD_USERS_SAGA,
    subject_id: subject_id,
    group_name: group_name,
    by_user_group: by_user_group,
    viewBy: viewBy,
    page: page
  };
};

export interface ILoadGroupssActionSaga {
  readonly type: typeof LOAD_GROUPS_SAGA;
  subject_id: string,
}
export const loadGroupsPropsSaga = (
  subject_id = "",
): ILoadGroupssActionSaga => {
  return {
    type: LOAD_GROUPS_SAGA,
    subject_id: subject_id,
  };
};


export interface ILoadTaskClaimerActionSaga {
  readonly type: typeof LOAD_TASK_CLAIMER_SAGA;
  tasks: ICamundaTask[]
}
export const loadTaskClaimerPropsSaga = (tasks: ICamundaTask[]): ILoadTaskClaimerActionSaga => {
  return {
    type: LOAD_TASK_CLAIMER_SAGA,
    tasks: tasks
  }
};
export interface ILoadCustomerTasksActionSaga {
  readonly type: typeof LOAD_CUSTOMER_TASKS_SAGA;
  tasks: ICamundaTask[],
  subject_id: string
}
export const loadCustomerTasksPropsSaga = (tasks: ICamundaTask[], subject_id: string): ILoadCustomerTasksActionSaga => {
  return {
    type: LOAD_CUSTOMER_TASKS_SAGA,
    tasks: tasks,
    subject_id: subject_id
  }
};
export interface ILoadCustomerProcessActionSaga {
  readonly type: typeof LOAD_CUSTOMER_PROCESS_SAGA;
  processes: ICamundaTask[]
}
export const loadCustomerProcessPropsSaga = (processes: ICamundaTask[]): ILoadCustomerProcessActionSaga => {
  return {
    type: LOAD_CUSTOMER_PROCESS_SAGA,
    processes: processes
  }
};


export interface ILoadTasksActionSaga {
  readonly type: typeof LOAD_TASKS_SAGA;
  include_assigned: boolean,
  my_task: boolean,
  overdue: boolean,
  subject_id: string,
  process_id?: string,
  task_id?: string,
  process_list?: string,
  task_list?: string,
  variable_name?: string,
  variable_value?: string,
  page?: number;
  size?: number;
  sort?: string;
}
export const loadTasksPropsSaga = (
  include_assigned: boolean,
  my_task: boolean,
  overdue: boolean,
  subject_id = "",
  process_id = "",
  task_id = "",
  process_list = "",
  task_list = "",
  variable_name = "",
  variable_value = "",
  page = 0,
  size = 25,
  sort = "",
): ILoadTasksActionSaga => {
  return {
    type: LOAD_TASKS_SAGA,
    include_assigned: include_assigned,
    my_task: my_task,
    overdue: overdue,
    subject_id: subject_id,
    process_id: process_id,
    task_id: task_id,
    process_list: process_list,
    task_list: task_list,
    variable_name: variable_name,
    variable_value: variable_value,
    page: page,
    size: size,
    sort: sort,
  };
};


/*//*/
export interface ILoadProcessTasksActionSaga {
  readonly type: typeof LOAD_PROCESS_TASKS_SAGA;
  subject_id: string,
  process_id: string,
}
export const loadProcessTasksPropsSaga = (
  subject_id = "",
  process_id = "",
): ILoadProcessTasksActionSaga => {
  return {
    type: LOAD_PROCESS_TASKS_SAGA,
    subject_id: subject_id,
    process_id: process_id,
  };
};
/*/*/


export interface ILoadProcessesActionSaga {
  readonly type: typeof LOAD_PROCESSES_SAGA;
  subject_id: string,
  process_id?: string,
  process_list?: string,
  variable_name?: string,
  variable_value?: string,
  page: number;
  size: number;
  sort: string;
}

export const loadProcessesPropsSaga = (
  subject_id: string,
  process_id = "",
  process_list = "",
  variable_name = "",
  variable_value = "",
  page = 0,
  size = 25,
  sort = "",
): ILoadProcessesActionSaga => {
  return {
    type: LOAD_PROCESSES_SAGA,
    subject_id: subject_id,
    process_id: process_id,
    process_list: process_list,
    variable_name: variable_name,
    variable_value: variable_value,
    page: page,
    size: size,
    sort: sort,
  };
};