import IUser from "../../dtos/IUser";
import {
    GET_USERS, SEARCH_USERS, SELECT_USER, RESET_ORDER_USER,
    GET_USER_ADDRESSES, UPDATE_IS_LOADING_USER_ADDRESSES
} from "../../sagas/users/types";
import { IGetUsersAction, IUpdateIsLoadingUserAddressesAction, usersActions } from "./types";
import IOrderAddress from "../../dtos/IOrderAddress";

export interface IUsersReducer {
    adminUsers: IGetUsersAction['payload'],
    ecommerceUsers: IGetUsersAction['payload'],
    userAddresses: IOrderAddress[],
    userResults: IUser[],
    selectedUser: IUser | null,
    loadingUserAddresses: IUpdateIsLoadingUserAddressesAction["payload"];

}
const initialState: IUsersReducer = {
    adminUsers: {
        users: [],
        totalUsersCount: 0
    },
    ecommerceUsers: {
        users: [],
        totalUsersCount: 0
    },
    userResults: [],
    userAddresses: [],
    selectedUser: null,
    loadingUserAddresses: {
        isLoadingUserAddresses: false,
        errorMessage: undefined,
    }
}
export default function usersReducer(state: IUsersReducer = initialState, action: usersActions) {
    switch (action.type) {
        case GET_USERS:
            return { ...state, ecommerceUsers: action.payload }
        case SEARCH_USERS:
            return {
                ...state,
                userResults: action.payload.userResults,
            }
        case SELECT_USER:
            return {
                ...state,
                selectedUser: action.payload.selectedUser,
            }
        case GET_USER_ADDRESSES:
            return {
                ...state,
                userAddresses: action.payload.userAddresses,
            }
        case UPDATE_IS_LOADING_USER_ADDRESSES:
            return {
                ...state,
                loadingUserAddresses: action.payload,
            };
        case RESET_ORDER_USER:
            return {
                ...state,
                selectedUser: null
            }
        default:
            return state;
    }
}