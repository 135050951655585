import { axiosInstance } from "../store"
import { IQuotation, IQuotationItem } from "../dtos/IQuotation";
import IAddress from "../dtos/IAddress";

export function quotationList(page = 1, size = 10): Promise<{ quotation_list: IQuotation[], total_count: any }> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/quotation?branch_id=${localStorage.getItem('branch_id')}&page=${page}&size=${size}&storefront_id=1`)
      .then((res) => {
        resolve({ quotation_list: res.data, total_count: res.headers['x-total-count'] });
      }).catch((err) => {
        reject({ quotation_list: [], total_count: 0 });
      })
  })
}



export function quotationById(quotationID: number): Promise<IQuotationItem[]> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/quotation/${quotationID}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err.response);
      })
  })
}
export function quotationItem(quotationID: number): Promise<IQuotationItem[]> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/quotation-item/${quotationID}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err.response);
      })
  })
}

export function quotations(page: number, size: number, sort: string): Promise<any> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/quotation?page=${page}&size=${size}&sort=${sort ? sort : "createdAt,desc"}`)
      .then((res) => {
        resolve({ quotation_list: res.data, total_count: res.headers['x-total-count'] });
      }).catch((err) => {
        reject(err.response);
      })
  })
}

export function branchAddress(): Promise<IAddress> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/address/branch?branch_id=${localStorage.getItem('branch_id')}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err.data);
      })
  })
}

export function Submit(quotationID: number, isRejected: boolean, items: any): Promise<IAddress> {
  return new Promise((resolve, reject) => {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/quotation/${quotationID}/submit?&rejected=${isRejected}`, items)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err.data);
      })
  })
}

export function Save(quotationID: number, items: any): Promise<IAddress> {
  return new Promise((resolve, reject) => {
    return axiosInstance.put(`${process.env.REACT_APP_SMART_STORAGE_URI}/quotation/${quotationID}/save`, items)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err.data);
      })
  })
}

export function QuotationToOrder(quotationID: number, orderID: number): Promise<IAddress> {
  return new Promise((resolve, reject) => {
    return axiosInstance.put(`${process.env.REACT_APP_SMART_STORAGE_URI}/quotation/${quotationID}/set-order?order_id=${orderID}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err.data);
      })
  })
}

