import { axiosInstance } from "../store";

export function login(email: string, password: string): Promise<any> {
    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    return axiosInstance.post(`${process.env.REACT_APP_KEY_API_GATEWAY}/4.0/auth_tokens`, formData,
        {
            headers: {
                'Storefront-Api-Access-Key': process.env.REACT_APP_KEY_API_GATEWAY_API_KEY || ''
            }
        })
        .then((res) => res.data);
}

export function getStorefronts(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/catalog/storefront`)
        .then(res => res.data).catch(e => console.log(e));
}