import IVendor from "../../dtos/IVendor";
import {
    LOAD_VENDORS, LOAD_VENDOR_PRODUCTS_QTYS, LOAD_VENDOR,
    LOAD_VENDORS_PRODUCTS, LOAD_VENDORS_RPODUCT_DETAILS,
    UPDATE_IS_LOADING_VENDORS_QTYS, UPDATE_IS_LOADING_VENDORS
} from "../../sagas/vendors/types";


export interface ILoadVendorsAction {
    readonly type: typeof LOAD_VENDORS;
    payload: {
        vendors: IVendor[],
        totalVendorsCount: number
    }
}
export const loadVendorsProps = (payload: ILoadVendorsAction['payload']): ILoadVendorsAction => {
    return {
        type: LOAD_VENDORS,
        payload: payload
    }
};

export interface ILoadVendorProductsQtysAction {
    readonly type: typeof LOAD_VENDOR_PRODUCTS_QTYS;
    payload: {
        vendorProductsQtys: any[],
    }
}
export const loadVendorProductsQtysProps = (payload: ILoadVendorProductsQtysAction['payload']): ILoadVendorProductsQtysAction => {
    return {
        type: LOAD_VENDOR_PRODUCTS_QTYS,
        payload: payload
    }
};

export interface ILoadVendorsProductsAction {
    readonly type: typeof LOAD_VENDORS_PRODUCTS;
    payload: {
        vendorsProducts: any[],
        totalVendorProducts: number
    }
}
export const loadVendorsProductsProps = (payload: ILoadVendorsProductsAction['payload']): ILoadVendorsProductsAction => {
    return {
        type: LOAD_VENDORS_PRODUCTS,
        payload: payload
    }
};

export interface ILoadVendorsProductDetailsAction {
    readonly type: typeof LOAD_VENDORS_RPODUCT_DETAILS;
    payload: {
        vendorsProductDetails: any[],
    }
}
export const loadVendorsProductDetailsProps = (payload: ILoadVendorsProductDetailsAction['payload']): ILoadVendorsProductDetailsAction => {
    return {
        type: LOAD_VENDORS_RPODUCT_DETAILS,
        payload: payload
    }
};

export interface ILoadVendorAction {
    readonly type: typeof LOAD_VENDOR;
    payload: {
        vendor: IVendor | null,
    }
}
export const loadVendorProps = (payload: ILoadVendorAction['payload']): ILoadVendorAction => {
    return {
        type: LOAD_VENDOR,
        payload: payload
    }
};

export interface IUpdateIsLoadingVendors {
    readonly type: typeof UPDATE_IS_LOADING_VENDORS;
    payload: {
        isLoadingVendors: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingVendorsProps = (
    payload: IUpdateIsLoadingVendors["payload"]
): IUpdateIsLoadingVendors => {
    return {
        type: UPDATE_IS_LOADING_VENDORS,
        payload: payload,
    };
};
export interface IUpdateIsLoadingVendorsQtys {
    readonly type: typeof UPDATE_IS_LOADING_VENDORS_QTYS;
    payload: {
        isLoadingVendorsQtys: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingVendorsQtysProps = (
    payload: IUpdateIsLoadingVendorsQtys["payload"]
): IUpdateIsLoadingVendorsQtys => {
    return {
        type: UPDATE_IS_LOADING_VENDORS_QTYS,
        payload: payload,
    };
};

export type VendorsActions = ILoadVendorsAction | ILoadVendorProductsQtysAction | ILoadVendorAction
    | ILoadVendorsProductsAction | ILoadVendorsProductDetailsAction
    | IUpdateIsLoadingVendorsQtys | IUpdateIsLoadingVendors;