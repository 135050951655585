import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { fetchQuestions, fetchSections } from "../../api/faqs";
import { IFAQQuestion, IFAQSection } from "../../dtos/IFAQs";
import { getFAQQuestionsProps, getFAQSectionsProps, updateIsLoadingFAQsProps } from "../../reducers/FAQs/types";
import { faqsActions, GET_QUESTIONS_SAGA, GET_SECTIONS_SAGA } from "./types";

function* getSections(action: faqsActions) {
    yield put(updateIsLoadingFAQsProps(true));
    const sections: { sections: IFAQSection[], totalCount: number } = yield call(fetchSections, action.payload.page, action.payload.size);
    yield put(getFAQSectionsProps(sections));
    yield put(updateIsLoadingFAQsProps(false));

}
function* watchGetSections() {
    yield takeLatest(GET_SECTIONS_SAGA, getSections);
}
function* getQuestions(action: any) {
    yield put(updateIsLoadingFAQsProps(true));
    const questions: { questions: IFAQQuestion[], totalCount: number } = yield call(fetchQuestions, action.payload.sectionId, action.payload.page, action.payload.size);
    yield put(getFAQQuestionsProps(questions));
    yield put(updateIsLoadingFAQsProps(false));

}
function* watchGetQuestions() {
    yield takeLatest(GET_QUESTIONS_SAGA, getQuestions);
}

export default [watchGetSections(), watchGetQuestions()];