export const LOAD_OPEN_TOTAL = "LOAD_OPEN_TOTAL";
export const LOAD_OPEN_TOTAL_SAGA = "LOAD_OPEN_TOTAL_SAGA";
export const LOAD_OPEN_HOT = "LOAD_OPEN_HOT";
export const LOAD_OPEN_HOT_SAGA = "LOAD_OPEN_HOT_SAGA";
export const LOAD_OPEN_WARM = "LOAD_OPEN_WARM";
export const LOAD_OPEN_WARM_SAGA = "LOAD_OPEN_WARM_SAGA";
export const LOAD_OPEN_COLD = "LOAD_OPEN_COLD";
export const LOAD_OPEN_COLD_SAGA = "LOAD_OPEN_COLD_SAGA";

export const LOAD_READY_PICKING_TOTAL = "LOAD_READY_PICKING_TOTAL";
export const LOAD_READY_PICKING_TOTAL_SAGA = "LOAD_READY_PICKING_TOTAL_SAGA";
export const LOAD_READY_PICKING_HOT = "LOAD_READY_PICKING_HOT";
export const LOAD_READY_PICKING_HOT_SAGA = "LOAD_READY_PICKING_HOT_SAGA";
export const LOAD_READY_PICKING_WARM = "LOAD_READY_PICKING_WARM";
export const LOAD_READY_PICKING_WARM_SAGA = "LOAD_READY_PICKING_WARM_SAGA";
export const LOAD_READY_PICKING_COLD = "LOAD_READY_PICKING_COLD";
export const LOAD_READY_PICKING_COLD_SAGA = "LOAD_READY_PICKING_COLD_SAGA";

export const LOAD_PACKED_TOTAL = "LOAD_PACKED_TOTAL";
export const LOAD_PACKED_TOTAL_SAGA = "LOAD_PACKED_TOTAL_SAGA";
export const LOAD_PACKED_HOT = "LOAD_PACKED_HOT";
export const LOAD_PACKED_HOT_SAGA = "LOAD_PACKED_HOT_SAGA";
export const LOAD_PACKED_WARM = "LOAD_PACKED_WARM";
export const LOAD_PACKED_WARM_SAGA = "LOAD_PACKED_WARM_SAGA";
export const LOAD_PACKED_COLD = "LOAD_PACKED_COLD";
export const LOAD_PACKED_COLD_SAGA = "LOAD_PACKED_COLD_SAGA";

export const LOAD_SHIPPED_TOTAL = "LOAD_SHIPPED_TOTAL";
export const LOAD_SHIPPED_TOTAL_SAGA = "LOAD_SHIPPED_TOTAL_SAGA";
export const LOAD_SHIPPED_HOT = "LOAD_SHIPPED_HOT";
export const LOAD_SHIPPED_HOT_SAGA = "LOAD_SHIPPED_HOT_SAGA";
export const LOAD_SHIPPED_WARM = "LOAD_SHIPPED_WARM";
export const LOAD_SHIPPED_WARM_SAGA = "LOAD_SHIPPED_WARM_SAGA";
export const LOAD_SHIPPED_COLD = "LOAD_SHIPPED_COLD";
export const LOAD_SHIPPED_COLD_SAGA = "LOAD_SHIPPED_COLD_SAGA";

export const UPDATE_IS_LOADING_SLA = "UPDATE_IS_LOADING_SLA";
export const UPDATE_IS_LOADING_SLA_SAGA = "UPDATE_IS_LOADING_SLA_SAGA";

export interface ILoadOpenTotalActionSaga {
  readonly type: typeof LOAD_OPEN_TOTAL_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadOpenTotalPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadOpenTotalActionSaga => {
  return {
    type: LOAD_OPEN_TOTAL_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};


export interface ILoadOpenHotActionSaga {
  readonly type: typeof LOAD_OPEN_HOT_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadOpenHotPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadOpenHotActionSaga => {
  return {
    type: LOAD_OPEN_HOT_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};


export interface ILoadOpenWarmActionSaga {
  readonly type: typeof LOAD_OPEN_WARM_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadOpenWarmPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadOpenWarmActionSaga => {
  return {
    type: LOAD_OPEN_WARM_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};
export interface ILoadOpenColdActionSaga {
  readonly type: typeof LOAD_OPEN_COLD_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadOpenColdPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadOpenColdActionSaga => {
  return {
    type: LOAD_OPEN_COLD_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};


export interface ILoadReadyPickingTotalActionSaga {
  readonly type: typeof LOAD_READY_PICKING_TOTAL_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadReadyPickingTotalPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadReadyPickingTotalActionSaga => {
  return {
    type: LOAD_READY_PICKING_TOTAL_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};
export interface ILoadReadyPickingHotActionSaga {
  readonly type: typeof LOAD_READY_PICKING_HOT_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadReadyPickingHotPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadReadyPickingHotActionSaga => {
  return {
    type: LOAD_READY_PICKING_HOT_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};
export interface ILoadReadyPickingWarmActionSaga {
  readonly type: typeof LOAD_READY_PICKING_WARM_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadReadyPickingWarmPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadReadyPickingWarmActionSaga => {
  return {
    type: LOAD_READY_PICKING_WARM_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};


export interface ILoadReadyPickingColdActionSaga {
  readonly type: typeof LOAD_READY_PICKING_COLD_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadReadyPickingColdPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadReadyPickingColdActionSaga => {
  return {
    type: LOAD_READY_PICKING_COLD_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};


export interface ILoadPackedTotalActionSaga {
  readonly type: typeof LOAD_PACKED_TOTAL_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadPackedTotalPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadPackedTotalActionSaga => {
  return {
    type: LOAD_PACKED_TOTAL_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};
export interface ILoadPackedHotActionSaga {
  readonly type: typeof LOAD_PACKED_HOT_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadPackedHotPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadPackedHotActionSaga => {
  return {
    type: LOAD_PACKED_HOT_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};
export interface ILoadPackedWarmActionSaga {
  readonly type: typeof LOAD_PACKED_WARM_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadPackedWarmPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadPackedWarmActionSaga => {
  return {
    type: LOAD_PACKED_WARM_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};

export interface ILoadPackedColdActionSaga {
  readonly type: typeof LOAD_PACKED_COLD_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadPackedColdPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadPackedColdActionSaga => {
  return {
    type: LOAD_PACKED_COLD_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};

export interface ILoadShippedTotalActionSaga {
  readonly type: typeof LOAD_SHIPPED_TOTAL_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadShippedTotalPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadShippedTotalActionSaga => {
  return {
    type: LOAD_SHIPPED_TOTAL_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};
export interface ILoadShippedHotActionSaga {
  readonly type: typeof LOAD_SHIPPED_HOT_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadShippedHotPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadShippedHotActionSaga => {
  return {
    type: LOAD_SHIPPED_HOT_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};
export interface ILoadShippedWarmActionSaga {
  readonly type: typeof LOAD_SHIPPED_WARM_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadShippedWarmPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadShippedWarmActionSaga => {
  return {
    type: LOAD_SHIPPED_WARM_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};
export interface ILoadShippedColdActionSaga {
  readonly type: typeof LOAD_SHIPPED_COLD_SAGA;
  fdcFlag: boolean;
  status: string;
  ergency: string;
  page: number;
  size: number;
  sort: string;
  orderId: string;
}
export const loadShippedColdPropsSaga = (
  fdcFlag = false,
  status = "",
  sort = "",
  ergency = "",
  page = 0,
  size = 25,
  orderId = "",
): ILoadShippedColdActionSaga => {
  return {
    type: LOAD_SHIPPED_COLD_SAGA,
    fdcFlag: fdcFlag,
    status: status,
    ergency: ergency,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
  };
};