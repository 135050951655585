import { ICamundaGroup, ICamundaTask, ICamundaUser } from "../../containers/Camunda/types";
import { LOAD_CUSTOMER_PROCESS, LOAD_CUSTOMER_TASKS, LOAD_GROUPS, LOAD_PROCESSES, LOAD_PROCESS_TASKS, LOAD_TASKS, LOAD_TASK_CLAIMER, LOAD_USERS, UPDATE_IS_LOADING_CUSTOMERS, UPDATE_IS_LOADING_GROUPS, UPDATE_IS_LOADING_TASKS, UPDATE_IS_LOADING_USERS } from "../../sagas/camunda/types";





export interface ILoadTaskClaimerAction {
  readonly type: typeof LOAD_TASK_CLAIMER;
  payload: {
    ClaimerData: any[],
  }
}
export const loadTaskClaimerProps = (payload: ILoadTaskClaimerAction['payload']): ILoadTaskClaimerAction => {
  return {
    type: LOAD_TASK_CLAIMER,
    payload: payload
  }
};
export interface ILoadCustomerTasksAction {
  readonly type: typeof LOAD_CUSTOMER_TASKS;
  payload: {
    CustomerData: any[],
  }
}
export const loadCustomerTasksProps = (payload: ILoadCustomerTasksAction['payload']): ILoadCustomerTasksAction => {
  return {
    type: LOAD_CUSTOMER_TASKS,
    payload: payload
  }
};
export interface ILoadCustomerProcessAction {
  readonly type: typeof LOAD_CUSTOMER_PROCESS;
  payload: {
    CustomerData: any[],
  }
}
export const loadCustomerProcessProps = (payload: ILoadCustomerProcessAction['payload']): ILoadCustomerProcessAction => {
  return {
    type: LOAD_CUSTOMER_PROCESS,
    payload: payload
  }
};

export interface IUpdateIsLoadingCustomers {
  readonly type: typeof UPDATE_IS_LOADING_CUSTOMERS;
  payload: {
    isLoadingCustomers: boolean;
    errorMessage: string | undefined;
  };
}
export const updateIsLoadingCustomerProps = (
  payload: IUpdateIsLoadingCustomers["payload"]
): IUpdateIsLoadingCustomers => {
  return {
    type: UPDATE_IS_LOADING_CUSTOMERS,
    payload: payload,
  };
};


export interface ILoadUserAction {
  readonly type: typeof LOAD_USERS;
  payload: {
    users: ICamundaUser[];
    totalUsersCount: number;
  };
}
export const loadUserProps = (
  payload: ILoadUserAction["payload"]
): ILoadUserAction => {
  return { type: LOAD_USERS, payload: payload };
};
export interface ILoadGroupAction {
  readonly type: typeof LOAD_GROUPS;
  payload: {
    groups: ICamundaGroup[];
    totalGroupsCount: number;
  };
}
export const loadGroupProps = (
  payload: ILoadGroupAction["payload"]
): ILoadGroupAction => {
  return {
    type: LOAD_GROUPS,
    payload: payload,
  };
};
export interface ILoadTasksAction {
  readonly type: typeof LOAD_TASKS;
  payload: {
    tasks: ICamundaTask[];
    totalTasksCount: number;
  };
}
export const loadTasksProps = (
  payload: ILoadTasksAction["payload"]
): ILoadTasksAction => {
  return {
    type: LOAD_TASKS,
    payload: payload,
  };
};

export interface ILoadProcessTasksAction {
  readonly type: typeof LOAD_PROCESS_TASKS;
  payload: {
    tasks: ICamundaTask[];
    totalTasksCount: number;
  };
}
export const loadProcessTasksProps = (
  payload: ILoadProcessTasksAction["payload"]
): ILoadProcessTasksAction => {
  return {
    type: LOAD_PROCESS_TASKS,
    payload: payload,
  };
};

export interface ILoadProcessesAction {
  readonly type: typeof LOAD_PROCESSES;
  payload: {
    processes: ICamundaTask[];
    totalProcessesCount: number;
  };
}
export const loadProcessesProps = (
  payload: ILoadProcessesAction["payload"]
): ILoadProcessesAction => {
  return {
    type: LOAD_PROCESSES,
    payload: payload,
  };
};

export interface IUpdateIsLoadingTasksAction {
  readonly type: typeof UPDATE_IS_LOADING_TASKS;
  payload: {
    isLoadingTasks: boolean;
    errorMessage: string | undefined;
  };
}
export const updateIsLoadingTasksProps = (
  payload: IUpdateIsLoadingTasksAction["payload"]
): IUpdateIsLoadingTasksAction => {
  return {
    type: UPDATE_IS_LOADING_TASKS,
    payload: payload,
  };
};
export interface IUpdateIsLoadingGroupsAction {
  readonly type: typeof UPDATE_IS_LOADING_GROUPS;
  payload: {
    isLoadingGroups: boolean;
    errorMessage: string | undefined;
  };
}
export const updateIsLoadingGroupsProps = (
  payload: IUpdateIsLoadingGroupsAction["payload"]
): IUpdateIsLoadingGroupsAction => {
  return {
    type: UPDATE_IS_LOADING_GROUPS,
    payload: payload,
  };
};
export interface IUpdateIsLoadingUsersAction {
  readonly type: typeof UPDATE_IS_LOADING_USERS;
  payload: {
    isLoadingUsers: boolean;
    errorMessage: string | undefined;
  };
}
export const updateIsLoadingUsersProps = (
  payload: IUpdateIsLoadingUsersAction["payload"]
): IUpdateIsLoadingUsersAction => {
  return {
    type: UPDATE_IS_LOADING_USERS,
    payload: payload,
  };
};

export type CamundaActions = ILoadTasksAction
  | ILoadCustomerTasksAction
  | ILoadUserAction
  | IUpdateIsLoadingCustomers
  | ILoadProcessesAction
  | ILoadTaskClaimerAction
  | ILoadCustomerProcessAction
  | IUpdateIsLoadingTasksAction
  | ILoadProcessTasksAction
  | IUpdateIsLoadingGroupsAction
  | IUpdateIsLoadingUsersAction
  | ILoadGroupAction
