import { IFAQSection, IFAQQuestion } from "../../dtos/IFAQs";
import { GET_QUESTIONS, GET_SECTIONS, UPDATE_IS_LOADING_FAQS } from "../../sagas/FAQs/types";
import { FAQActions } from "./types";

interface IFAQState {
    sections: { sections: IFAQSection[], totalCount: number },
    questions: { questions: IFAQQuestion[], totalCount: number },
    isLoading: boolean,
}
const initialState: IFAQState = {
    sections: { sections: [], totalCount: 0 },
    questions: { questions: [], totalCount: 0 },
    isLoading: false,
}
export default function FAQReducer(state = initialState, action: FAQActions) {
    switch (action.type) {
        case GET_SECTIONS:
            return {
                ...state,
                sections: action.payload
            }
        case GET_QUESTIONS:
            return {
                ...state,
                questions: action.payload
            }
        case UPDATE_IS_LOADING_FAQS:
            return {
                ...state,
                isLoading: action.payload,
            }
        default:
            return state;
    }
}