import {
    LOAD_FEATURE_VALUES, UPDATE_IS_LOADING_FEATURE_VALUES,
    LOAD_FEATURES, UPDATE_IS_LOADING_FEATURES
} from "../../sagas/features/types";


export interface ILoadFeatureValuesAction {
    readonly type: typeof LOAD_FEATURE_VALUES;
    payload: {
        featureValues: any[],
    }
}
export const loadFeatureValuesProps = (payload: ILoadFeatureValuesAction['payload']): ILoadFeatureValuesAction => {
    return {
        type: LOAD_FEATURE_VALUES,
        payload: payload
    }
};

export interface IUpdateIsLoadingFeatureValues {
    readonly type: typeof UPDATE_IS_LOADING_FEATURE_VALUES;
    payload: {
        isLoadingFeatureValues: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingFeatureValuesProps = (
    payload: IUpdateIsLoadingFeatureValues["payload"]
): IUpdateIsLoadingFeatureValues => {
    return {
        type: UPDATE_IS_LOADING_FEATURE_VALUES,
        payload: payload,
    };
};

export interface ILoadFeaturesAction {
    readonly type: typeof LOAD_FEATURES;
    payload: {
        features: any[],
        totalFeaturesCount: number
    }
}
export const loadFeaturesProps = (payload: ILoadFeaturesAction['payload']): ILoadFeaturesAction => {
    return {
        type: LOAD_FEATURES,
        payload: payload
    }
};

export interface IUpdateIsLoadingFeatures {
    readonly type: typeof UPDATE_IS_LOADING_FEATURES;
    payload: {
        isLoadingFeatures: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingFeaturesProps = (
    payload: IUpdateIsLoadingFeatures["payload"]
): IUpdateIsLoadingFeatures => {
    return {
        type: UPDATE_IS_LOADING_FEATURES,
        payload: payload,
    };
};
export type FeaturesActions = ILoadFeaturesAction | IUpdateIsLoadingFeatures
    | ILoadFeatureValuesAction | IUpdateIsLoadingFeatureValues;