import { CategoriesActions, IUpdateIsLoadingCategories } from "./types";
import {
    LOAD_PARENT_CATEGORIES, LOAD_CATEGORIES, UPDATE_IS_LOADING_CATEGORIES
} from "../../sagas/categories/types";

interface ICategoryStatus {
    parentCategories: any[];
    categories: any[];
    loadingCategories: IUpdateIsLoadingCategories["payload"];
}
const CategoryStatus: ICategoryStatus = {
    parentCategories: [],
    categories: [],
    loadingCategories: {
        isLoadingCategories: false,
        errorMessage: undefined,
    }
}
const categoryReducer = (state = CategoryStatus, action: CategoriesActions): ICategoryStatus => {
    switch (action.type) {
        case LOAD_PARENT_CATEGORIES:
            return {
                ...state,
                parentCategories: action.payload.parentCategories,
            }
        case LOAD_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories
            }
        case UPDATE_IS_LOADING_CATEGORIES:
            return {
                ...state,
                loadingCategories: action.payload,
            };
        default:
            return state;
    }
}
export default categoryReducer