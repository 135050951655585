import { ICampaign } from "../../dtos/campaigns";
import { GET_CAMPAIGNS, UPDATE_IS_LOADING_CAMPAIGNS } from "../../sagas/campaigns/types";

interface IGetCampaignsAction {
    type: typeof GET_CAMPAIGNS,
    payload: { campaigns: ICampaign[], totalCount: number },
}
export function getCampaignsProps(data: IGetCampaignsAction["payload"]): IGetCampaignsAction {
    return {
        type: GET_CAMPAIGNS,
        payload: { campaigns: data.campaigns, totalCount: data.totalCount }
    }
}
interface IUpdateCampaignLoadingState {
    type: typeof UPDATE_IS_LOADING_CAMPAIGNS,
    payload: boolean
}
export function updateIsLoadingCampaignsProps(isLoading: boolean): IUpdateCampaignLoadingState {
    return {
        type: UPDATE_IS_LOADING_CAMPAIGNS,
        payload: isLoading
    }
}
export type campaignActions = IUpdateCampaignLoadingState | IGetCampaignsAction;
