import { IQuotation } from "../../dtos/IQuotation"
import { LOAD_QUOTATION_LIST, UPDATE_IS_LOADING_QUOTATION } from "../../sagas/quotation/types"
import { QuotationActions } from "./types"

interface ISmartOrderingState {
  quotationList: IQuotation[],
  totalQuotationCount: number,
  loadingQuotations: any,
}
const SmartOrderingState: ISmartOrderingState = {
  quotationList: [],
  totalQuotationCount: 0,
  loadingQuotations: {
    isLoadingQuotations: false,
    errorMessage: undefined
  },
}
const QuotationReducer = (state = SmartOrderingState, action: QuotationActions): ISmartOrderingState => {
  switch (action.type) {
    case LOAD_QUOTATION_LIST:
      console.log("wrver", action.payload)
      return {
        ...state,
        quotationList: action.payload.quotationList,
        totalQuotationCount: action.payload.totalQuotationCount
      }
    case UPDATE_IS_LOADING_QUOTATION:
      return {
        ...state,
        loadingQuotations: action.payload
      }
    default:
      return state;
  }
}
export default QuotationReducer;