import { LOAD_STORE_FRONT, StorefrontActions } from './types';
interface IStorefrontState {
    storefrontId: number | string | null,
}
const Storefront: IStorefrontState = {
    storefrontId: localStorage.getItem("storefront")
}
const storefrontReducer = (state = Storefront, action: StorefrontActions): IStorefrontState => {
    switch (action.type) {
        case LOAD_STORE_FRONT:
            return {
                ...state,
                storefrontId: action.payload.storefrontId
            }
        default:
            return state;
    }
}
export default storefrontReducer;