import { ITrackingNumber } from "../../dtos/IOrder";

export const LOAD_ORDERS = "LOAD_ORDERS";
export const LOAD_ORDERS_SAGA = "LOAD_ORDERS_SAGA";

export const LOAD_ORDERS_TRACKING = "LOAD_ORDERS_TRACKING";
export const LOAD_ORDERS_TRACKING_SAGA = "LOAD_ORDERS_TRACKING_SAGA";

export const LOAD_OPEN_ORDERS = "LOAD_OPEN_ORDERS";
export const LOAD_OPEN_ORDERS_SAGA = "LOAD_OPEN_ORDERS_SAGA";

export const LOAD_READY_PICKING = "LOAD_READY_PICKING";
export const LOAD_READY_PICKING_SAGA = "LOAD_READY_PICKING_SAGA";

export const LOAD_ORDER_HISTORY = "LOAD_ORDER_HISTORY"
export const LOAD_ORDER_HISTORY_SAGA = "LOAD_ORDER_HISTORY_SAGA"

export const LOAD_NF_ORDERS = "LOAD_NF_ORDERS";
export const LOAD_NF_ORDERS_SAGA = "LOAD_NF_ORDERS_SAGA";

export const LOAD_NF_ORDER_LINES = "LOAD_NF_ORDER_LINES";
export const LOAD_NF_ORDER_LINES_SAGA = "LOAD_NF_ORDER_LINES_SAGA";

export const UPDATE_IS_LOADING_ORDERS = "UPDATE_IS_LOADING_ORDERS";

export interface ILoadOrdersActionSaga {
  readonly type: typeof LOAD_ORDERS_SAGA;
  page: number;
  size: number;
  sort: string;
  orderId: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  dateFrom: string;
  dateTo: string;
  status: string;
  userId: string;
  trackingNumber: string;
  excluded: string;
  has_NFOL: any;
}
export const loadOrdersPropsSaga = (
  page = 0,
  size = 25,
  sort = "",
  orderId = "",
  firstName = "",
  lastName = "",
  email = "",
  mobile = "",
  dateFrom = "",
  dateTo = "",
  status = "",
  userId = "",
  trackingNumber = "",
  excluded = "",
  has_NFOL = "",
): ILoadOrdersActionSaga => {
  return {
    type: LOAD_ORDERS_SAGA,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobile: mobile,
    dateFrom: dateFrom,
    dateTo: dateTo,
    status: status,
    userId: userId,
    trackingNumber: trackingNumber,
    excluded: excluded,
    has_NFOL: has_NFOL,
  };
};
export interface ILoadOrdersWithTrackingSaga {
  readonly type: typeof LOAD_ORDERS_TRACKING_SAGA;
  page: number;
  size: number;
  sort: string;
  tracking_search: any;
}
export const loadOrdersWithTrackingPropsSaga = (
  page = 0,
  size = 25,
  sort = "",
  tracking_search = {},
): ILoadOrdersWithTrackingSaga => {
  return {
    type: LOAD_ORDERS_TRACKING_SAGA,
    page: page,
    size: size,
    sort: sort,
    tracking_search: tracking_search,
  };
};

export interface ILoadOrderHistoryActionSaga {
  readonly type: typeof LOAD_ORDER_HISTORY_SAGA;
  orderId: string;
  page: number;
  size: number;
  sort: string;
}
export const loadOrderHistoryPropsSaga = (
  orderId = "",
  page = 0,
  size = 25,
  sort = "",
): ILoadOrderHistoryActionSaga => {
  return {
    type: LOAD_ORDER_HISTORY_SAGA,
    orderId: orderId,
    page: page,
    size: size,
    sort: sort,
  };
};

export interface ILoadOpenOrdersActionSaga {
  readonly type: typeof LOAD_OPEN_ORDERS_SAGA;
  page: number;
  size: number;
  sort: string;
  orderId: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  dateFrom: string;
  dateTo: string;
  status: string;
  userId: string;
  trackingNumber: string;
  excluded: string;
}
export const loadOpenOrdersPropsSaga = (
  status = "",
  page = 0,
  size = 25,
  sort = "",
  orderId = "",
  firstName = "",
  lastName = "",
  email = "",
  mobile = "",
  dateFrom = "",
  dateTo = "",
  userId = "",
  trackingNumber = "",
  excluded = ""
): ILoadOpenOrdersActionSaga => {
  return {
    type: LOAD_OPEN_ORDERS_SAGA,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobile: mobile,
    dateFrom: dateFrom,
    dateTo: dateTo,
    status: status,
    userId: userId,
    trackingNumber: trackingNumber,
    excluded: excluded,
  };
};

export interface ILoadNFOrdersActionSaga {
  readonly type: typeof LOAD_NF_ORDERS_SAGA;
  page: number;
  size: number;
  sort: string;
  excluded: string;
}
export const loadNFOrdersPropsSaga = (
  page = 0,
  size = 25,
  sort = "",
  excluded = "",
): ILoadNFOrdersActionSaga => {
  return {
    type: LOAD_NF_ORDERS_SAGA,
    page: page,
    size: size,
    sort: sort,
    excluded: excluded
  };
};

export interface ILoadNFOrderLinesActionSaga {
  readonly type: typeof LOAD_NF_ORDER_LINES_SAGA;
  page: number;
  size: number;
}
export const loadNFOrderLinesPropsSaga = (
  page = 0,
  size = 25,

): ILoadNFOrderLinesActionSaga => {
  return {
    type: LOAD_NF_ORDER_LINES_SAGA,
    page: page,
    size: size,
  };
};

export interface ILoadReadyPickingActionSaga {
  readonly type: typeof LOAD_READY_PICKING_SAGA;
  page: number;
  size: number;
  sort: string;
  orderId: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  dateFrom: string;
  dateTo: string;
  status: string;
  userId: string;
  trackingNumber: string;
  excluded: string;
}

export const loadReadyPickingPropsSaga = (
  status = "",
  page = 0,
  size = 25,
  sort = "",
  orderId = "",
  firstName = "",
  lastName = "",
  email = "",
  mobile = "",
  dateFrom = "",
  dateTo = "",
  userId = "",
  trackingNumber = "",
  excluded = ""
): ILoadReadyPickingActionSaga => {
  return {
    type: LOAD_READY_PICKING_SAGA,
    page: page,
    size: size,
    sort: sort,
    orderId: orderId,
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobile: mobile,
    dateFrom: dateFrom,
    dateTo: dateTo,
    status: status,
    userId: userId,
    trackingNumber: trackingNumber,
    excluded: excluded,
  };
};
