import IUser from "../../dtos/IUser";

export const GET_USERS_SAGA = "GET_USERS_SAGA";
export const GET_USERS = "GET_USERS";

export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_SAGA = "SEARCH_USERS_SAGA";

export const SELECT_USER = "SELECT_USER";
export const SELECT_USER_SAGA = "SELECT_USER_SAGA";

export const RESET_ORDER_USER = "RESET_ORDER_USER";
export const RESET_ORDER_USER_SAGA = "RESET_ORDER_USER_SAGA";

export const GET_USER_ADDRESSES = "GET_USER_ADDRESSES";
export const GET_USER_ADDRESSES_SAGA = "GET_USER_ADDRESSES_SAGA";

export const UPDATE_IS_LOADING_USER_ADDRESSES = "UPDATE_IS_LOADING_USER_ADDRESSES";

export interface IGetUsersAction {
    readonly type: typeof GET_USERS_SAGA,
    payload: IGetUsersPayload,
}
interface IGetUsersPayload {
    componentId?: number,
    page?: number,
    size?: number,
    sort?: string,
    name?: string,
    email?: string,
    first_name?: string,
    last_name?: string
}
export function getUsersPropsSaga(filters: IGetUsersPayload = {}): IGetUsersAction {
    return {
        type: GET_USERS_SAGA,
        payload: filters,
    }
}

export interface ISearchUsersActionSaga {
    readonly type: typeof SEARCH_USERS_SAGA;
    firstName: string,
    lastName: string,
    mobileNumber: string,
    email: string
}
export const searchUsersPropsSaga = (firstName = '', lastName = '', mobileNumber = '', email = ''): ISearchUsersActionSaga => {
    return {
        type: SEARCH_USERS_SAGA,
        firstName: firstName,
        lastName: lastName,
        mobileNumber: mobileNumber,
        email: email
    }
};

export interface IAddSelectedUserActionSaga {
    readonly type: typeof SELECT_USER_SAGA;
    user: IUser | null
}
export const addSelectedUserPropsSaga = (user = null): IAddSelectedUserActionSaga => {
    return {
        type: SELECT_USER_SAGA,
        user: user,
    }
};
export interface IResetOrderUserActionSaga {
    readonly type: typeof RESET_ORDER_USER_SAGA;
}
export const resetOrderUserPropsSaga = (): IResetOrderUserActionSaga => {
    return {
        type: RESET_ORDER_USER_SAGA,
    }
};

export interface IGetUserAddressesActionSaga {
    readonly type: typeof GET_USER_ADDRESSES_SAGA;
    subjectId: string
}
export const getUserAddressesPropsSaga = (subjectId = ''): IGetUserAddressesActionSaga => {
    return {
        type: GET_USER_ADDRESSES_SAGA,
        subjectId: subjectId
    }
};