import { ICamundaGroup, ICamundaUser } from "../../containers/Camunda/types";
import { axiosInstance } from "../../store";
import notification from '../../components/Notification';


export function createUser(subject_id: string, payload: any) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/workflow/admin/create-user?subject_id=${subject_id}`, { ...payload })
    .then((res) => {
      notification("success", "User created");
    })
    .catch((err) => {
      notification("error", "Error in user creation");
    });
}
export function editUser(id: string, subject_id: string, payload: any) {
  return axiosInstance
    .put(`${process.env.REACT_APP_API_URL}/workflow/admin/${id}/edit?subject_id=${subject_id}`, { ...payload })
    .then((res) => {
      notification("success", "User successfuly Edited");
    })
    .catch((err) => {
      notification("error", "Error in user edition");
    });
}

export function getuser(id: string, subject_id: string): Promise<ICamundaUser> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/admin/${id}/user?subject_id=${subject_id}`)
    .then((res) => {
      if (res.status == 200) return res.data;
    })
    .catch((err) => { });
}

export function getusers(subject_id: string, group_id = "", by_user_group = "true", page = 0, size = 25): Promise<{ users: ICamundaUser[], totalUsersCount: number }> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/admin/users?subject_id=${subject_id}&by_group=${group_id}&by_user_group=${by_user_group}&page=${page}&size=${size}`)
    .then((res) => {
      if (res.status == 200) {
        return { users: res.data, totalUsersCount: parseInt(res.headers["total-count"]) };
      } else {
        return { users: [], totalUsersCount: 0 };
      }
    })
    .catch((err) => {
      return { users: [], totalUsersCount: 0 };
    });
}

export function createGroup(subject_id: string, payload: any) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/workflow/admin/create-group?subject_id=${subject_id}`, { ...payload })
    .then((res) => {
      notification("success", "Group created");
    })
    .catch((err) => {
      notification("error", "Error in group creation");
    });
}

export function getGroups(subject_id: string): Promise<{ groups: ICamundaGroup[], totalGroupsCount: number }> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/admin/groups?subject_id=${subject_id}&size=250`)
    .then((res) => {
      if (res.status == 200) {
        return { groups: res.data, totalGroupsCount: parseInt(res.headers["total-count"]) };
      } else {
        return { groups: [], totalGroupsCount: 0 };
      }
    })
    .catch((err) => {
      return { groups: [], totalGroupsCount: 0 };
    });
}
export function getPerformance(subject_id: string): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/performance/dashboard?subject_id=${subject_id}`)
    .then((res) => {
      if (res.status == 200) return res.data;
    })
    .catch((err) => { });
}