import { put, takeLatest, call } from "redux-saga/effects";
import { displayErrorMessageProps } from "../../reducers/global/types";
import { ILoadOpenColdActionSaga, ILoadOpenHotActionSaga, ILoadOpenTotalActionSaga, ILoadOpenWarmActionSaga, ILoadPackedColdActionSaga, ILoadPackedHotActionSaga, ILoadPackedTotalActionSaga, ILoadPackedWarmActionSaga, ILoadReadyPickingColdActionSaga, ILoadReadyPickingHotActionSaga, ILoadReadyPickingTotalActionSaga, ILoadReadyPickingWarmActionSaga, ILoadShippedColdActionSaga, ILoadShippedHotActionSaga, ILoadShippedTotalActionSaga, ILoadShippedWarmActionSaga, LOAD_OPEN_COLD_DASHBOARD_SAGA, LOAD_OPEN_HOT_DASHBOARD_SAGA, LOAD_OPEN_TOTAL, LOAD_OPEN_TOTAL_DASHBOARD_SAGA, LOAD_OPEN_WARM_DASHBOARD_SAGA, LOAD_PACKED_COLD_DASHBOARD_SAGA, LOAD_PACKED_HOT_DASHBOARD_SAGA, LOAD_PACKED_TOTAL_DASHBOARD_SAGA, LOAD_PACKED_WARM_DASHBOARD_SAGA, LOAD_READY_PICKING_COLD_DASHBOARD_SAGA, LOAD_READY_PICKING_HOT_DASHBOARD_SAGA, LOAD_READY_PICKING_TOTAL_DASHBOARD_SAGA, LOAD_READY_PICKING_WARM_DASHBOARD_SAGA, LOAD_SHIPPED_COLD_DASHBOARD_SAGA, LOAD_SHIPPED_HOT_DASHBOARD_SAGA, LOAD_SHIPPED_TOTAL_DASHBOARD_SAGA, LOAD_SHIPPED_WARM_DASHBOARD_SAGA } from "./types";
import { loadOpenColdProps, loadOpenHotProps, loadOpenTotalProps, loadOpenWarmProps, loadPackedColdProps, loadPackedHotProps, loadPackedTotalProps, loadPackedWarmProps, loadReadyPickingColdProps, loadReadyPickingHotProps, loadReadyPickingTotalProps, loadReadyPickingWarmProps, loadShippedColdProps, loadShippedHotProps, loadShippedTotalProps, loadShippedWarmProps, updateIsLoadingSlaProps } from "../../reducers/sla/types";
import { fetchSla, fetchSlaOrders } from "../../api/sla";

function* loadOpenTotalDashboardData(action: ILoadOpenTotalActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadOpenTotalProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenTotalDashboard() {
  yield takeLatest(LOAD_OPEN_TOTAL_DASHBOARD_SAGA, loadOpenTotalDashboardData);
}

function* loadOpenHotDashboardData(action: ILoadOpenHotActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadOpenHotProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenHotDashboard() {
  yield takeLatest(LOAD_OPEN_HOT_DASHBOARD_SAGA, loadOpenHotDashboardData);
}


function* loadOpenWarmDashboardData(action: ILoadOpenWarmActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadOpenWarmProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenWarmDashboard() {
  yield takeLatest(LOAD_OPEN_WARM_DASHBOARD_SAGA, loadOpenWarmDashboardData);
}

function* loadOpenColdDashboardData(action: ILoadOpenColdActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadOpenColdProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenColdDashboard() {
  yield takeLatest(LOAD_OPEN_COLD_DASHBOARD_SAGA, loadOpenColdDashboardData);
}

function* loadReadyPickingTotalDashboardData(action: ILoadReadyPickingTotalActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadReadyPickingTotalProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadReadyPickingTotalDashboard() {
  yield takeLatest(LOAD_READY_PICKING_TOTAL_DASHBOARD_SAGA, loadReadyPickingTotalDashboardData);
}
function* loadReadyPickingHotDashboardData(action: ILoadReadyPickingHotActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadReadyPickingHotProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadReadyPickingHotDashboard() {
  yield takeLatest(LOAD_READY_PICKING_HOT_DASHBOARD_SAGA, loadReadyPickingHotDashboardData);
}

function* loadReadyPickingWarmDashboardData(action: ILoadReadyPickingWarmActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadReadyPickingWarmProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadReadyPickingWarmDashboard() {
  yield takeLatest(LOAD_READY_PICKING_WARM_DASHBOARD_SAGA, loadReadyPickingWarmDashboardData);
}

function* loadReadyPickingColdDashboardData(action: ILoadReadyPickingColdActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadReadyPickingColdProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadReadyPickingColdDashboard() {
  yield takeLatest(LOAD_READY_PICKING_COLD_DASHBOARD_SAGA, loadReadyPickingColdDashboardData);
}

function* loadPackedTotalDashboardData(action: ILoadPackedTotalActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadPackedTotalProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadPackedTotalDashboard() {
  yield takeLatest(LOAD_PACKED_TOTAL_DASHBOARD_SAGA, loadPackedTotalDashboardData);
}


function* loadPackedHotDashboardData(action: ILoadPackedHotActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadPackedHotProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadPackedHotDashboard() {
  yield takeLatest(LOAD_PACKED_HOT_DASHBOARD_SAGA, loadPackedHotDashboardData);
}
function* loadPackedWarmDashboardData(action: ILoadPackedWarmActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadPackedWarmProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadPackedWarmDashboard() {
  yield takeLatest(LOAD_PACKED_WARM_DASHBOARD_SAGA, loadPackedWarmDashboardData);
}
function* loadPackedColdDashboardData(action: ILoadPackedColdActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadPackedColdProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadPackedColdDashboard() {
  yield takeLatest(LOAD_PACKED_COLD_DASHBOARD_SAGA, loadPackedColdDashboardData);
}
function* loadShippedTotalDashboardData(action: ILoadShippedTotalActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadShippedTotalProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadShippedTotalDashboard() {
  yield takeLatest(LOAD_SHIPPED_TOTAL_DASHBOARD_SAGA, loadShippedTotalDashboardData);
}

function* loadShippedHotDashboardData(action: ILoadShippedHotActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadShippedHotProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadShippedHotDashboard() {
  yield takeLatest(LOAD_SHIPPED_HOT_DASHBOARD_SAGA, loadShippedHotDashboardData);
}
function* loadShippedWarmDashboardData(action: ILoadShippedWarmActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadShippedWarmProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadShippedWarmDashboard() {
  yield takeLatest(LOAD_SHIPPED_WARM_DASHBOARD_SAGA, loadShippedWarmDashboardData);
}
function* loadShippedColdDashboardData(action: ILoadShippedColdActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    yield put(
      loadShippedColdProps({
        orders: ordersData.orders,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadShippedColdDashboard() {
  yield takeLatest(LOAD_SHIPPED_COLD_DASHBOARD_SAGA, loadShippedColdDashboardData);
}


export default [
  watchLoadOpenTotalDashboard(),
  watchLoadOpenHotDashboard(),
  watchLoadOpenWarmDashboard(),
  watchLoadOpenColdDashboard(),

  watchLoadReadyPickingTotalDashboard(),
  watchLoadReadyPickingHotDashboard(),
  watchLoadReadyPickingWarmDashboard(),
  watchLoadReadyPickingColdDashboard(),

  watchLoadPackedTotalDashboard(),
  watchLoadPackedHotDashboard(),
  watchLoadPackedWarmDashboard(),
  watchLoadPackedColdDashboard(),

  watchLoadShippedTotalDashboard(),
  watchLoadShippedHotDashboard(),
  watchLoadShippedWarmDashboard(),
  watchLoadShippedColdDashboard(),
];
