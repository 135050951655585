
export const LOAD_BRANDS = "LOAD_BRANDS";
export const LOAD_BRANDS_SAGA = "LOAD_BRANDS_SAGA";

export const LOAD_BRAND_PRODUCTS_QTYS = "LOAD_BRAND_PRODUCTS_QTYS";
export const LOAD_BRAND_PRODUCTS_QTYS_SAGA = "LOAD_BRAND_PRODUCTS_QTYS_SAGA"

export const LOAD_BRAND = "LOAD_BRAND";
export const LOAD_BRAND_SAGA = "LOAD_BRAND_SAGA";

export const RESET_BRAND_SAGA = "RESET_BRAND_SAGA";


export const UPDATE_IS_LOADING_BRANDS = "UPDATE_IS_LOADING_BRANDS";
export const UPDATE_IS_LOADING_BRANDS_QTYS = "UPDATE_IS_LOADING_BRANDS_QTYS";

export interface ILoadBrandsActionSaga {
    readonly type: typeof LOAD_BRANDS_SAGA;
    page: number,
    size: number,
    sort: string,
}
export const loadBrandsPropsSaga = (page = 0, size = 25, sort = ''): ILoadBrandsActionSaga => {
    return {
        type: LOAD_BRANDS_SAGA,
        page: page,
        size: size,
        sort: sort,
    }
};

export interface ILoadBrandProductsQtysActionSaga {
    readonly type: typeof LOAD_BRAND_PRODUCTS_QTYS_SAGA;
    brands: any[]
}
export const loadBrandProductsQtysPropsSaga = (brands: any[]): ILoadBrandProductsQtysActionSaga => {
    return {
        type: LOAD_BRAND_PRODUCTS_QTYS_SAGA,
        brands: brands
    }
};

export interface ILoadBrandActionSaga {
    readonly type: typeof LOAD_BRAND_SAGA;
    brandId: string
}
export const loadBrandPropsSaga = (brandId = ''): ILoadBrandActionSaga => {
    return {
        type: LOAD_BRAND_SAGA,
        brandId: brandId,
    }
};
export interface IResetBrandActionSaga {
    readonly type: typeof RESET_BRAND_SAGA;
}
export const resetBrandPropsSaga = (): IResetBrandActionSaga => {
    return {
        type: RESET_BRAND_SAGA,
    }
};
