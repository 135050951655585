import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_FEATURE_VALUES_SAGA, ILoadFeatureValuesActionSaga,
    RESET_FEATURE_VALUES_SAGA,
    LOAD_FEATURES_SAGA, ILoadFeaturesActionSaga
} from "./types";
import { fetchFeatureValues, fetchAllFeatures } from "../../api/features";
import {
    loadFeatureValuesProps, updateIsLoadingFeatureValuesProps,
    loadFeaturesProps, updateIsLoadingFeaturesProps
} from "../../reducers/features/types"

function* loadFeatures(action: ILoadFeaturesActionSaga) {
    try {
        yield put(updateIsLoadingFeaturesProps({ isLoadingFeatures: true, errorMessage: undefined })
        );
        let features: { features: any[], totalFeaturesCount: number } = yield call(fetchAllFeatures, action.page, action.size);
        const filtered = features.features.length > 0 ? features.features.filter((f: any) => { return f.id != 1 }) : [];
        features = {
            ...features,
            features: filtered
        }
        yield put(loadFeaturesProps(features))
        yield put(updateIsLoadingFeaturesProps({ isLoadingFeatures: false, errorMessage: undefined })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingFeaturesProps({ isLoadingFeatures: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadFeatures() {
    yield takeLatest(LOAD_FEATURES_SAGA, loadFeatures)
}



async function loadFeatureValuesData(features: any, sort: string) {
    return await Promise.all(features.map(async (f: any) => {
        const data: any = await fetchFeatureValues(f.id, sort, 0, 10000);

        return {
            feature: f,
            featureValues: data.featureValues
        }

    })).then((res: any) => {

        return res;
    })
}
function* loadFeaturesValues(action: ILoadFeatureValuesActionSaga) {
    try {
        yield put(updateIsLoadingFeatureValuesProps({ isLoadingFeatureValues: true, errorMessage: undefined }));
        if (action.features.length > 0) {
            const featureValues: any[] = yield call(loadFeatureValuesData, action.features, action.sort);
            yield put(loadFeatureValuesProps({ featureValues }));
            yield put(updateIsLoadingFeatureValuesProps({ isLoadingFeatureValues: false, errorMessage: undefined }));
        }

    }
    catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingFeatureValuesProps({ isLoadingFeatureValues: false, errorMessage: undefined }));
    }
}
export function* watchLoadFeaturesValues() {
    yield takeLatest(LOAD_FEATURE_VALUES_SAGA, loadFeaturesValues)
}
function* resetFeatureValuesData(action: ILoadFeatureValuesActionSaga) {
    try {
        yield put(loadFeatureValuesProps({ featureValues: [] }))
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchResetFeaturesValuesData() {
    yield takeLatest(RESET_FEATURE_VALUES_SAGA, resetFeatureValuesData)
}
export default [
    watchLoadFeaturesValues(),
    watchResetFeaturesValuesData(),
    watchLoadFeatures()
]