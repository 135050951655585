import { put, takeLatest, call } from 'redux-saga/effects'
import { fetchUsers, searchUser, fetchUserAddresses } from '../../api/users';
import IUser from '../../dtos/IUser';
import IOrderAddress from '../../dtos/IOrderAddress';
import { displayErrorMessageProps } from "../../reducers/global/types";
import { getUsersProps, searchUsersProps, addSelectedUserProps, resetOrderUserProps, getUserAddressesProps, updateIsLoadingUserAddressesProps } from '../../reducers/users/types';
import {
    GET_USERS_SAGA, IGetUsersAction,
    SEARCH_USERS_SAGA, ISearchUsersActionSaga,
    SELECT_USER_SAGA, IAddSelectedUserActionSaga,
    RESET_ORDER_USER_SAGA, IResetOrderUserActionSaga,
    GET_USER_ADDRESSES_SAGA, IGetUserAddressesActionSaga
} from './types';

function* getUsers(action: IGetUsersAction) {
    yield put(
        updateIsLoadingUserAddressesProps({
            isLoadingUserAddresses: true,
            errorMessage: undefined,
        })
    );
    const { users, totalUsersCount } = yield call(fetchUsers, action.payload.componentId, action.payload.page, action.payload.size, action.payload.sort, action.payload.name, action.payload.email, action.payload.first_name, action.payload.last_name);
    yield put(
        updateIsLoadingUserAddressesProps({
            isLoadingUserAddresses: false,
            errorMessage: undefined,
        })
    );
    yield put(getUsersProps({ users: users, totalUsersCount: totalUsersCount }));
}
function* watchGetUsers() {
    yield takeLatest(GET_USERS_SAGA, getUsers);
}

function* loaduserResults(action: ISearchUsersActionSaga) {
    try {
        yield put(
            updateIsLoadingUserAddressesProps({
                isLoadingUserAddresses: true,
                errorMessage: undefined,
            })
        );
        const userResults: IUser[] = yield call(searchUser, action.firstName, action.lastName, action.mobileNumber, action.email);
        yield put(searchUsersProps({ userResults: userResults }))
        yield put(
            updateIsLoadingUserAddressesProps({
                isLoadingUserAddresses: false,
                errorMessage: undefined,
            })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(
            updateIsLoadingUserAddressesProps({
                isLoadingUserAddresses: false,
                errorMessage: undefined,
            })
        );
    }
}
export function* watchLoadUserResults() {
    yield takeLatest(SEARCH_USERS_SAGA, loaduserResults)
}

function* addUserData(action: IAddSelectedUserActionSaga) {
    try {
        if (action.user != null) {
            let selectedUser: IUser = action.user
            yield put(addSelectedUserProps({ selectedUser: selectedUser }))
        }
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchAddUserData() {
    yield takeLatest(SELECT_USER_SAGA, addUserData)
}
function* resetUserData(action: IResetOrderUserActionSaga) {
    try {
        let selectedUser: null = null;
        yield put(resetOrderUserProps({ selectedUser: selectedUser }))
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchResetUserData() {
    yield takeLatest(RESET_ORDER_USER_SAGA, resetUserData)
}

function* loadUserAddressesData(action: IGetUserAddressesActionSaga) {
    try {
        yield put(
            updateIsLoadingUserAddressesProps({
                isLoadingUserAddresses: true,
                errorMessage: undefined,
            })
        );
        const userAddresses: IOrderAddress[] = yield call(fetchUserAddresses, action.subjectId);
        yield put(getUserAddressesProps({ userAddresses: userAddresses }))
        yield put(
            updateIsLoadingUserAddressesProps({
                isLoadingUserAddresses: false,
                errorMessage: undefined,
            })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadUserAddressesData() {
    yield takeLatest(GET_USER_ADDRESSES_SAGA, loadUserAddressesData)
}
export default [
    watchGetUsers(),
    watchLoadUserResults(),
    watchAddUserData(),
    watchResetUserData(),
    watchLoadUserAddressesData(),
];