import { IFAQQuestion, IFAQSection } from "../../dtos/IFAQs";
import { GET_QUESTIONS, GET_SECTIONS, UPDATE_IS_LOADING_FAQS } from "../../sagas/FAQs/types";

interface IGetSectionsActions {
    readonly type: typeof GET_SECTIONS,
    payload: { sections: IFAQSection[], totalCount: number },
}
export function getFAQSectionsProps(sections: { sections: IFAQSection[], totalCount: number }): IGetSectionsActions {
    return {
        type: GET_SECTIONS,
        payload: sections,
    }
}
interface IGetQuestionsActions {
    readonly type: typeof GET_QUESTIONS,
    payload: { questions: IFAQQuestion[], totalCount: number },
}
export function getFAQQuestionsProps(questions: { questions: IFAQQuestion[], totalCount: number }): IGetQuestionsActions {
    return {
        type: GET_QUESTIONS,
        payload: questions
    }
}
interface IUpdateIsLoadingActions {
    readonly type: typeof UPDATE_IS_LOADING_FAQS,
    payload: boolean,
}
export function updateIsLoadingFAQsProps(isLoading: boolean): IUpdateIsLoadingActions {
    return {
        type: UPDATE_IS_LOADING_FAQS,
        payload: isLoading
    }
}
export type FAQActions = IGetQuestionsActions | IGetSectionsActions | IUpdateIsLoadingActions;