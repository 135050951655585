import React from 'react';
import { colors } from '../../assets/styles/constants';
import Popconfirm from '../Feedback/Popconfirm';

export default function ({ index,onDeleteCell,text = '' }) {
  return (
    <Popconfirm
      title="Sure to delete?"
      okText="DELETE"
      cancelText="No"
      onConfirm={() => onDeleteCell(index)}
    >
      <div style={{ width: "100%",textAlign: "center" }}>
        <a href="#" style={{ color: "#666666",fontWeight: 'bold',textDecoration: 'underline' }}>{text ? text : 'x'}</a>
      </div>
    </Popconfirm>
  );
}
