import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { updateAuthUserPropsSaga } from '../../sagas/global/types';
// import LoginPage from '../../pages/login';
// import InvitationValidationPage from "../../pages/invitationValidation"


interface IAuthRouteProps {
    children: React.ReactNode
}

function AuthRoute(props: IAuthRouteProps) {
    const dispatch = useDispatch();
    const globalReducer = useSelector((state: AppState) => state.globalReducer);
    const loginWithKeycloak: boolean = (process.env.REACT_APP_AUTH_KEY_CLOAK && process.env.REACT_APP_AUTH_KEY_CLOAK.toLowerCase() == 'true') ? true : false;
    useEffect(() => {
        if (!window.location.hash.includes('invitationToken')) {
            dispatch(updateAuthUserPropsSaga({ authWithKeycloak: loginWithKeycloak }));
        }
    }, []);

    let view;
    // if (window.location.hash.includes('invitationToken')) {
    //     view = (<InvitationValidationPage />)
    // }
    // else
    if (globalReducer.authUser === undefined || globalReducer.authUser.token === null) {
        // view = (<>{
        //     loginWithKeycloak ?
        //         <p>loading...</p>
        //         :
        //         <p>normal login</p>
        // }</>);
    }
    else if (globalReducer.authUser === undefined) {
        // view = (<p>undefined...</p>);
    }
    else if (!globalReducer.authUser.userValidated) {
        // view = (<p>user not validated...</p>)
    }
    else if (globalReducer.authUser.error) {
        view = globalReducer.authUser.error;
    }
    else {
        view = props.children;
    }
    return (
        <>
            {
                view
            }
        </>
    )
}

export default AuthRoute;