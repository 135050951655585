import { ICamundaProcess, ICamundaTask } from "../../containers/Camunda/types";
import { axiosInstance } from "../../store";
import notification from '../../components/Notification';
import { v4 as uuid } from 'uuid';
import { jsonToFormData } from "../../helpers";


export async function fetchProcesses(
  subject_id = "",
  process_id = "",
  process_list = "",
  variable_name = "",
  variable_value = "",
  page = 0,
  size = 25,
  sort = "",
): Promise<{ processes: ICamundaProcess[], totalProcessesCount: number }> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/process?proc_instance_id=${process_id}&subject_id=${subject_id}&page=${page}&size=${size}&sort${sort}`,
      { params: { process_list: process_list, variable_name: variable_name, variable_value: variable_value } })
    .then((res) => {
      if (res.status == 200) {
        return {
          processes: res.data,
          totalProcessesCount: parseInt(res.headers["total-count"])
        };
      } else {
        return { processes: [], totalProcessesCount: 0 };
      }
    }).catch((err) => { return { processes: [], totalProcessesCount: 0 } })
}

export function fetchProcessTasks(procId: string, subject_id: string): Promise<{ tasks: ICamundaTask[], totaltasksCount: number }> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/history-task?proc_instance_id=${procId}&subject_id=${subject_id}`)
    .then((res) => {
      if (res.status == 200) {
        return {
          tasks: res.data,
          totaltasksCount: parseInt(res.headers["total-count"])
        };
      } else { return { tasks: [], totaltasksCount: 0 } }
    })
    .catch((err) => { return { tasks: [], totaltasksCount: 0 } });
}

export function availableProcess(subject_id: string): Promise<{ key: string, name: string }[]> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/process/available-process?subject_id=${subject_id}`)
    .then((res) => { if (res.status == 200) return res.data }).catch((err) => { })
}

export function fetchVariables(procId: string, subject_id: string, is_historic: boolean): Promise<Map<string, string>> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/variable?proc_instance_id=${procId}&is_historic=${is_historic}&subject_id=${subject_id}`)
    .then((res) => { if (res.status == 200) return res.data }).catch((err) => { })
}

export function fetchAvailableProcessTasks(subjectID: string): Promise<ICamundaTask[]> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/user-task/available?subject_id=${subjectID}`)
    .then((res) => { if (res.status == 200) return res.data }).catch((err) => { return [] });
}

export function startProcess(subject_id: string, payload: any, withAttacments = false, files?: any): Promise<ICamundaTask[]> {
  let payloadCopy = payload;
  let formData = new FormData();
  formData.append("model", JSON.stringify(payloadCopy))

  if (withAttacments) {
    if (files.files) {
      files.files.forEach((img: any, index: any) => {
        formData.append(`file`, img.originFileObj);
      });
    } else {
      formData.append(`file`, "");
    }
  }
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_URL}/workflow/process/start?subject_id=${subject_id}`, formData, {
        headers: {
          "Accept-Language": "",
          "Content-Type": "multipart/form-data",
          "Accept": "*/*",
        }
      })
      .then((res) => { if (res.status == 200 || res.status == 204) resolve(res.data); })
      .catch((err) => {
        notification("error", "Process didn't start");
        reject(err.response);
      });
  })
}
export function uploadImage(image: any): Promise<any> {
  let formData = new FormData();
  formData.append("image", image);
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/workflow/attachment/upload-image?id=${Math.floor(Math.random() * 700)}`, formData, {
      headers: {
        "Accept-Language": "",
        "Content-Type": "multipart/form-data",
        "Accept": "*/*",
      }
    })
    .then((res) => { if (res.status == 200) return res }).catch((err) => { return err });
}

