import { BrandsActions, IUpdateIsLoadingBrands, IUpdateIsLoadingBrandsQtys } from "./types";
import {
    LOAD_BRANDS, LOAD_BRAND_PRODUCTS_QTYS, LOAD_BRAND,
    UPDATE_IS_LOADING_BRANDS_QTYS, UPDATE_IS_LOADING_BRANDS
} from "../../sagas/brands/types";

interface IBrandStatus {
    brands: any[];
    totalBrandsCount: number;
    brand: any | null;
    loadingBrands: IUpdateIsLoadingBrands["payload"];
    loadingBrandsQtys: IUpdateIsLoadingBrandsQtys["payload"];
}
const BrandStatus: IBrandStatus = {
    brands: [],
    totalBrandsCount: 0,
    brand: null,
    loadingBrands: {
        isLoadingBrands: false,
        errorMessage: undefined,
    },
    loadingBrandsQtys: {
        isLoadingBrandsQtys: false,
        errorMessage: undefined,
    }
}
const brandReducer = (state = BrandStatus, action: BrandsActions): IBrandStatus => {
    switch (action.type) {
        case LOAD_BRANDS:
            return {
                ...state,
                brands: action.payload.brands,
                totalBrandsCount: action.payload.totalBrandsCount
            }
        case LOAD_BRAND_PRODUCTS_QTYS:
            return {
                ...state,
                brands: action.payload.brandProductsQtys,
            }
        case LOAD_BRAND:
            return {
                ...state,
                brand: action.payload.brand
            }
        case UPDATE_IS_LOADING_BRANDS:
            return {
                ...state,
                loadingBrands: action.payload,
            };
        case UPDATE_IS_LOADING_BRANDS_QTYS:
            return {
                ...state,
                loadingBrandsQtys: action.payload,
            };
        default:
            return state;
    }
}
export default brandReducer