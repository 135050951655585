import { ICampaign } from "../../dtos/campaigns";

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const UPDATE_IS_LOADING_CAMPAIGNS = "UPDATE_IS_LOADING_CAMPAIGNS";

export const GET_CAMPAIGNS_SAGA = "GET_CAMPAIGNS_SAGA";
export const UPDATE_IS_LOADING_CAMPAIGNS_SAGA = "UPDATE_IS_LOADING_CAMPAIGNS_SAGA";

export interface IGetCampaignsSagaAction {
    type: typeof GET_CAMPAIGNS_SAGA,
    payload: {
        page: number,
        size: number,
        type: string,
        tag: string
    }
}
export function getCampaignsSagaProps(page = 0, size = 10, type = "", tag = ""): IGetCampaignsSagaAction {
    return {
        type: GET_CAMPAIGNS_SAGA,
        payload: {
            page: page,
            size: size,
            type: type,
            tag: tag
        }
    }
}
export interface IUpdateCampaignIsLoadingSagaAction {
    type: typeof UPDATE_IS_LOADING_CAMPAIGNS_SAGA,
    payload: boolean
}
export function setLoadingCampaignSagaProps(isLoading: boolean): IUpdateCampaignIsLoadingSagaAction {
    return {
        type: UPDATE_IS_LOADING_CAMPAIGNS_SAGA,
        payload: isLoading
    }
}

export type campaignSagaActionTypes = IGetCampaignsSagaAction | IUpdateCampaignIsLoadingSagaAction
