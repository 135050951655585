import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { fetchCampaigns } from "../../api/campaigns";
import { ICampaign } from "../../dtos/campaigns";
import { getCampaignsProps, updateIsLoadingCampaignsProps } from "../../reducers/campaigns/types";
import { campaignSagaActionTypes, GET_CAMPAIGNS_SAGA, IGetCampaignsSagaAction } from "./types";

function* getCampaigns(action: IGetCampaignsSagaAction) {
    yield put(updateIsLoadingCampaignsProps(true));
    const campaigns: { campaigns: ICampaign[], totalCount: number } = yield call(fetchCampaigns, action.payload.page, action.payload.size, action.payload.type, action.payload.tag);
    yield put(getCampaignsProps(campaigns));
    yield put(updateIsLoadingCampaignsProps(false));
}
function* watchGetCampaigns() {
    yield takeLatest(GET_CAMPAIGNS_SAGA, getCampaigns)
}
export default [watchGetCampaigns()];