import globalReducer from './global/globalReducer';
import headerReducer from './header/headerReducer';
import orderReducer from './orders/orderReducer';
import slaReducer from './sla/slaReducer';
import usersReducer from './users';
import storefrontReducer from './storefront';
import productReducer from './products/productsReducer';
import FAQsReducer from './FAQs/index';
import camundaReducer from './camunda/camundaReducer';
import vendorReducer from './vendors/vendorsReducer';
import campaignReducer from './campaigns';
import featureReducer from "./features/featuresReducer";
import brandReducer from "./brands/brandsReducer";
import categoryReducer from "./categories/categoriesReducer";
import QuotationReducer from "./quotation/index";

const reducers = {
    globalReducer,
    headerReducer,
    orderReducer,
    slaReducer,
    usersReducer,
    storefrontReducer,
    productReducer,
    FAQsReducer,
    camundaReducer,
    vendorReducer,
    campaignReducer,
    featureReducer,
    brandReducer,
    categoryReducer,
    QuotationReducer
}

export default reducers;