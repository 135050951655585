import { all } from "redux-saga/effects";
import watchGlobal from "./global";
import watchHeader from './header';
import watchOrders from "./orders";
import watchSla from "./sla";
import watchDashboard from "./dashboard"
import watchUsers from "./users";
import watchProducts from "./products";
import watchFAQs from "./FAQs";
import watchCamunda from "./camunda"
import watchVendors from "./vendors"
import watchCampaigns from "./campaigns"
import watchFeatures from "./features";
import watchBrands from "./brands"
import watchCategories from "./categories";
import watchQuotation from "./quotation"
export default function* rootSaga() {
  yield all([
    ...watchGlobal,
    ...watchHeader,
    ...watchOrders,
    ...watchSla,
    ...watchDashboard,
    ...watchUsers,
    ...watchProducts,
    ...watchFAQs,
    ...watchCamunda,
    ...watchVendors,
    ...watchCampaigns,
    ...watchFeatures,
    ...watchBrands,
    ...watchCategories,
    ...watchQuotation,
  ])
}