import { FeaturesActions, IUpdateIsLoadingFeatureValues, IUpdateIsLoadingFeatures } from "./types";
import {
    LOAD_FEATURE_VALUES, UPDATE_IS_LOADING_FEATURE_VALUES,
    LOAD_FEATURES, UPDATE_IS_LOADING_FEATURES
} from "../../sagas/features/types";

interface IFeatureState {
    featureValues: any[];
    loadingFeatureValues: IUpdateIsLoadingFeatureValues["payload"];

    features: any[];
    totalFeaturesCount: number;
    loadingFeatures: IUpdateIsLoadingFeatures["payload"];

}
const FeatureStatus: IFeatureState = {
    featureValues: [],
    loadingFeatureValues: {
        isLoadingFeatureValues: false,
        errorMessage: undefined,
    },
    features: [],
    totalFeaturesCount: 0,
    loadingFeatures: {
        isLoadingFeatures: false,
        errorMessage: undefined
    }
}
const featureReducer = (state = FeatureStatus, action: FeaturesActions): IFeatureState => {
    switch (action.type) {
        case LOAD_FEATURE_VALUES:
            return {
                ...state,
                featureValues: action.payload.featureValues,
            }
        case UPDATE_IS_LOADING_FEATURE_VALUES:
            return {
                ...state,
                loadingFeatureValues: action.payload,
            };

        case LOAD_FEATURES:
            return {
                ...state,
                features: action.payload.features,
                totalFeaturesCount: action.payload.totalFeaturesCount
            }
        case UPDATE_IS_LOADING_FEATURES:
            return {
                ...state,
                loadingFeatures: action.payload,
            };
        default:
            return state;
    }
}
export default featureReducer