import { IFAQQuestion, IFAQSection } from "../../dtos/IFAQs";
export const GET_SECTIONS = "GET_SECTIONS";
export const GET_SECTIONS_SAGA = "GET_SECTIONS_SAGA";

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SAGA = "GET_QUESTIONS_SAGA";

export const UPDATE_IS_LOADING_FAQS = "UPDATE_IS_LOADING_FAQS";
export const UPDATE_IS_LOADING_SAGA_FAQS = "UPDATE_IS_LOADING_SAGA";

interface IGetSectionsSagaAction {
    readonly type: typeof GET_SECTIONS_SAGA,
    payload: {
        size: number,
        page: number
    }
}
export function getSectionsPropsSaga(page = 0, size = 10): IGetSectionsSagaAction {
    return {
        type: GET_SECTIONS_SAGA,
        payload: {
            size: size,
            page: page,
        }
    }
}
interface IGetQuestionsSagaAction {
    readonly type: typeof GET_QUESTIONS_SAGA,
    payload: {
        sectionId: number | string,
        size: number,
        page: number,
    },
}
export function getQuestionsSagaProps(sectionId: number | string, page = 0, size = 10): IGetQuestionsSagaAction {
    return {
        type: GET_QUESTIONS_SAGA,
        payload: {
            sectionId: sectionId,
            size: size,
            page: page,
        },
    }
}
export type faqsActions = IGetQuestionsSagaAction | IGetSectionsSagaAction;
