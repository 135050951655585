import { LOAD_QUOTATION_LIST, UPDATE_IS_LOADING_QUOTATION } from "../../sagas/quotation/types";

export interface ILoadQuotationAction {
  readonly type: typeof LOAD_QUOTATION_LIST;
  payload: {
    quotationList: any[],
    totalQuotationCount: number
  }
}
export const loadQuotationProps = (payload: ILoadQuotationAction['payload']): ILoadQuotationAction => {
  return {
    type: LOAD_QUOTATION_LIST,
    payload: payload
  }
};


export interface IUpdateIsLoadingQuotationsAction {
  readonly type: typeof UPDATE_IS_LOADING_QUOTATION;
  payload: {
    isLoadingQuotations: boolean,
    errorMessage: string | undefined
  }
}
export const updateIsLoadingQuotationsProps = (payload: IUpdateIsLoadingQuotationsAction['payload']): IUpdateIsLoadingQuotationsAction => {
  return {
    type: UPDATE_IS_LOADING_QUOTATION,
    payload: payload
  }
};

export type QuotationActions = ILoadQuotationAction | IUpdateIsLoadingQuotationsAction