export const LOAD_STORE_FRONT = "LOAD_STORE_FRONT"
export interface ILoadStorefrontAction {
    readonly type: typeof LOAD_STORE_FRONT;
    payload: {
        storefrontId: number,
    }
}
export const loadStorefrontProps = (payload: ILoadStorefrontAction['payload']): ILoadStorefrontAction => {
    return {
        type: LOAD_STORE_FRONT,
        payload: payload
    }
};

export type StorefrontActions = ILoadStorefrontAction;