import { IUpdateIsLoadingProducts, ProductActions } from "./types";
import {
    LOAD_PRODUCTS, ADD_ORDER_PRODUCT, REMOVE_PRODUCTS, RESET_ORDER_ITEMS,
    LOAD_COMMON_PRODUCTS,
    UPDATE_IS_LOADING_PRODUCTS
} from "../../sagas/products/types";
import IProduct, { IOrderProduct } from "../../dtos/IProduct";

interface IProductStatus {
    products: IProduct[],
    selectedItems: IOrderProduct[],
    loadingProducts: IUpdateIsLoadingProducts["payload"];
    commonProducts: IProduct[],
    totalCommonProductsCount: number
}
const ProductStatus: IProductStatus = {
    products: [],
    selectedItems: [],
    loadingProducts: {
        isLoadingProducts: false,
        errorMessage: undefined,
    },
    commonProducts: [],
    totalCommonProductsCount: 0,
}

const productReducer = (state = ProductStatus, action: ProductActions): IProductStatus => {
    switch (action.type) {
        case LOAD_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
            }
        case LOAD_COMMON_PRODUCTS:
            return {
                ...state,
                commonProducts: action.payload.commonProducts,
                totalCommonProductsCount: action.payload.totalCommonProductsCount
            }
        case ADD_ORDER_PRODUCT:
            return {
                ...state,
                selectedItems: state.selectedItems.concat(action.payload.selectedItems),
            }
        case REMOVE_PRODUCTS:
            return {
                ...state,
                selectedItems: state.selectedItems.filter((item: IOrderProduct) => !item.selected)
            }
        case RESET_ORDER_ITEMS:
            return {
                ...state,
                selectedItems: []
            }
        case UPDATE_IS_LOADING_PRODUCTS:
            return {
                ...state,
                loadingProducts: action.payload,
            };
        default:
            return state;
    }
}
export default productReducer;
