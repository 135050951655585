import axios from "axios";
import { IFAQQuestion, IFAQSection } from "../dtos/IFAQs";
import { handlStatusCodeMessage, jsonToFormData } from "../helpers";
import { axiosInstance } from "../store";

export function fetchSections(page = 0, size = 10): Promise<{ sections: IFAQSection[], totalCount: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_PRODUCTS_URI}/faq-sections?page=${page}&size=${size}`, {
        headers: {
            "Accept-Language": ""
        }
    }).then((res: any) => {
        return res.status == 200 ? { sections: res.data, totalCount: parseInt(res.headers["x-total-count"]) } : { sections: [], totalCount: 0 };
    }).catch((err) => {
        handlStatusCodeMessage({ code: err.response?.status, entity: 'faq' });
        return { sections: [], totalCount: 0 }
    })
}
export function fetchSection(sectionId: number): Promise<IFAQQuestion> {
    return axiosInstance.get(`${process.env.REACT_APP_PRODUCTS_URI}/faq-sections?section_id=${sectionId}`, {
        headers: {
            "Accept-Language": ""
        }
    }).then((res: any) => {
        return res.status == 200 ? res.data : undefined;
    }).catch((err) => {
        handlStatusCodeMessage({ code: err.response?.status, entity: 'faq' });
        return undefined
    })
}
export function fetchQuestions(sectionId: number | string, page = 0, size = 10): Promise<{ questions: IFAQQuestion[], totalCount: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_PRODUCTS_URI}/faq-questions?section_id=${sectionId}&page=${page}&size=${size}`, {
        headers: {
            "Accept-Language": ""
        }
    }).then((res: any) => {
        return res.status == 200 ? { questions: res.data, totalCount: parseInt(res.headers["x-total-count"]) } : { questions: [], totalCount: 0 };
    }).catch((err) => {
        handlStatusCodeMessage({ code: err.response?.status, entity: 'faq' });
        return { questions: [], totalCount: 0 }
    })
}
export function createQuestion(question: any): Promise<boolean> {
    const formData = jsonToFormData(question);
    return axiosInstance.post(`${process.env.REACT_APP_PRODUCTS_URI}/faq-questions`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    }).then((res: any) => {
        return res.status == 200 ? true : false;
    }).catch((err) => {
        handlStatusCodeMessage({ code: err.response?.status, entity: 'faq' });
        return false;
    })
}
export function updateFAQSection(section: any, token: string): Promise<boolean> {
    const formData = jsonToFormData(section);
    return axios.put(`${process.env.REACT_APP_PRODUCTS_URI}/faq-sections/${section.section_id}?storefront_id=1`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then((res: any) => {
            return true;
        }).catch((err: any) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'faq' });
            return false;
        })
}
export function createFAQSection(section: any): Promise<boolean> {
    const formData = jsonToFormData(section);
    return axiosInstance.post(`${process.env.REACT_APP_PRODUCTS_URI}/faq-sections`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    })
        .then((res: any) => {
            return true;
        }).catch((err: any) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'faq' })
            return false;
        })
}
export function deleteFAQQuestion(questionId: number | string): Promise<boolean> {
    return axiosInstance.delete(`${process.env.REACT_APP_PRODUCTS_URI}/faq-questions/${questionId}`)
        .then((res: any) => {
            return true;
        }).catch((err: any) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'faq' })
            return false;
        })
}