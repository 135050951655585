import { ICamundaTask } from "../../containers/Camunda/types";
import { axiosInstance } from "../../store";
import notification from '../../components/Notification';

export async function fetchTasks(
  include_assigned: boolean,
  my_task: boolean,
  overdue: boolean,
  subject_id = "",
  process_id = "",
  task_id = "",
  process_list = "",
  task_list = "",
  variable_name = "",
  variable_value = "",
  page = 0,
  size = 25,
  sort = "",
): Promise<{ tasks: ICamundaTask[], totaltasksCount: number }> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/user-task?proc_instance_id=${process_id}&task_id=${task_id}&my_task=${my_task}&subject_id=${subject_id}&include_assigned=${include_assigned}&overdue=${overdue}&page=${page}&size=${size}&sort${sort}`,
      { params: { process_key: process_list, variable_name: variable_name, variable_value: variable_value } })
    .then((res) => {
      if (res.status == 200) {
        return {
          tasks: res.data,
          totaltasksCount: parseInt(res.headers["total-count"])
        };
      } else {
        return { tasks: [], totaltasksCount: 0 };
      }
    }).catch((err) => { return { tasks: [], totaltasksCount: 0 } })
}

export function fetchTask(taskId: string, subject_id: string): Promise<ICamundaTask> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/user-task/${taskId}?&subject_id=${subject_id}`)
    .then((res) => {
      if (res.status == 200) return res.data;
      return [];
    }).catch((err) => { return [] });
}

export function submitForm(taskID: string, subject_id: string, payload = {}): Promise<any> {
  return axiosInstance
    .put(`${process.env.REACT_APP_API_URL}/workflow/user-task/${taskID}/submit?subject_id=${subject_id}`, payload)
    .then((res) => {
      if (res.status == 204) {
        notification("success", "Task Submitted successfully");
        return res.data;
      }
    }).catch((err) => {
      notification("error", "Task Submition Failed");
    })
}

export async function claimTask(taskID: string, subject_id: string): Promise<any> {
  return axiosInstance
    .put(`${process.env.REACT_APP_API_URL}/workflow/user-task/${taskID}/claim?subject_id=${subject_id}`)
    .then((res) => {
      if (res.status == 204) {
        notification("success", "Task Claimed");
        return res.data;
      }
    }).catch((err) => { notification("error", "Task not Claimed") });
}

export async function reassignTask(taskID: string, assignee: string, subject_id: string): Promise<any> {
  return axiosInstance
    .put(`${process.env.REACT_APP_API_URL}/workflow/user-task/${taskID}/reassign?assignee=${assignee}&subject_id=${subject_id}`)
    .then((res) => {
      if (res.status == 204) {
        if (assignee) {
          notification("success", "Task Assigned");
        } else {
          notification("success", "Assignee Cleared");
        }
        return res.data;
      }
    }).catch((err) => { notification("error", "Task didn't Reassigned") });
}

export function getForm(formName: string): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/forms/${formName}`)
    .then(res => res.data).catch(err => { })
}

export function getUsersInGroup(subject_id: string): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/admin/group-user?subject_id=${subject_id}`)
    .then(res => { return res.data }).catch(err => { })
}
export function getTasksNumbers(subject_id: string): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/workflow/user-task/numbers?subject_id=${subject_id}`)
    .then(res => { return res.data }).catch(err => { })
}

