import { VendorsActions, IUpdateIsLoadingVendors, IUpdateIsLoadingVendorsQtys } from "./types";
import {
    LOAD_VENDORS, LOAD_VENDOR_PRODUCTS_QTYS, LOAD_VENDOR,
    LOAD_VENDORS_PRODUCTS, LOAD_VENDORS_RPODUCT_DETAILS,
    UPDATE_IS_LOADING_VENDORS, UPDATE_IS_LOADING_VENDORS_QTYS,
} from "../../sagas/vendors/types";
import IVendor from "../../dtos/IVendor";
import { stat } from "fs";

interface IVendorStatus {
    vendors: IVendor[];
    totalVendorsCount: number;
    vendor: IVendor | null;
    loadingVendors: IUpdateIsLoadingVendors["payload"];
    loadingVendorsQtys: IUpdateIsLoadingVendorsQtys["payload"];
    vendorsProducts: any[];
    totalVendorProducts: number;
}
const VendorStatus: IVendorStatus = {
    vendors: [],
    totalVendorsCount: 0,
    vendor: null,
    loadingVendors: {
        isLoadingVendors: false,
        errorMessage: undefined,
    },
    loadingVendorsQtys: {
        isLoadingVendorsQtys: false,
        errorMessage: undefined,
    },
    vendorsProducts: [],
    totalVendorProducts: 0
}
const vendorReducer = (state = VendorStatus, action: VendorsActions): IVendorStatus => {
    switch (action.type) {
        case LOAD_VENDORS:
            return {
                ...state,
                vendors: action.payload.vendors,
                totalVendorsCount: action.payload.totalVendorsCount
            }
        case LOAD_VENDOR_PRODUCTS_QTYS:
            return {
                ...state,
                vendors: action.payload.vendorProductsQtys,
            }
        case LOAD_VENDORS_PRODUCTS:
            return {
                ...state,
                vendorsProducts: action.payload.vendorsProducts,
                totalVendorProducts: action.payload.totalVendorProducts
            }
        case LOAD_VENDORS_RPODUCT_DETAILS:
            return {
                ...state,
                vendorsProducts: action.payload.vendorsProductDetails
            }
        case LOAD_VENDOR:
            return {
                ...state,
                vendor: action.payload.vendor
            }
        case UPDATE_IS_LOADING_VENDORS:
            return {
                ...state,
                loadingVendors: action.payload,
            };
        case UPDATE_IS_LOADING_VENDORS_QTYS:
            return {
                ...state,
                loadingVendorsQtys: action.payload,
            };
        default:
            return state;
    }
}
export default vendorReducer