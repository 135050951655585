import {
    LOAD_PARENT_CATEGORIES, LOAD_CATEGORIES, UPDATE_IS_LOADING_CATEGORIES
} from "../../sagas/categories/types";


export interface ILoadParentCategoriesAction {
    readonly type: typeof LOAD_PARENT_CATEGORIES;
    payload: {
        parentCategories: any[],
    }
}
export const loadParentCategoriesProps = (payload: ILoadParentCategoriesAction['payload']): ILoadParentCategoriesAction => {
    return {
        type: LOAD_PARENT_CATEGORIES,
        payload: payload
    }
};

export interface ILoadCategoriesAction {
    readonly type: typeof LOAD_CATEGORIES;
    payload: {
        categories: any[],
    }
}
export const loadCategoriesProps = (payload: ILoadCategoriesAction['payload']): ILoadCategoriesAction => {
    return {
        type: LOAD_CATEGORIES,
        payload: payload
    }
};

export interface IUpdateIsLoadingCategories {
    readonly type: typeof UPDATE_IS_LOADING_CATEGORIES;
    payload: {
        isLoadingCategories: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingCategoriesProps = (
    payload: IUpdateIsLoadingCategories["payload"]
): IUpdateIsLoadingCategories => {
    return {
        type: UPDATE_IS_LOADING_CATEGORIES,
        payload: payload,
    };
};

export type CategoriesActions = ILoadParentCategoriesAction | ILoadCategoriesAction | IUpdateIsLoadingCategories;