import {
    LOAD_PRODUCTS, ADD_ORDER_PRODUCT, REMOVE_PRODUCTS, RESET_ORDER_ITEMS, UPDATE_IS_LOADING_PRODUCTS,
    LOAD_COMMON_PRODUCTS
} from "../../sagas/products/types";
import IProduct, { IOrderProduct } from "../../dtos/IProduct";

export interface ILoadProductsAction {
    readonly type: typeof LOAD_PRODUCTS;
    payload: {
        products: IProduct[],
    }
}
export const loadProductsProps = (payload: ILoadProductsAction['payload']): ILoadProductsAction => {
    return {
        type: LOAD_PRODUCTS,
        payload: payload
    }
};

export interface ILoadCommonProductsAction {
    readonly type: typeof LOAD_COMMON_PRODUCTS;
    payload: {
        commonProducts: IProduct[],
        totalCommonProductsCount: number
    }
}
export const loadCommonProductsProps = (payload: ILoadCommonProductsAction['payload']): ILoadCommonProductsAction => {
    return {
        type: LOAD_COMMON_PRODUCTS,
        payload: payload
    }
};

export interface IAddSelectedItemsAction {
    readonly type: typeof ADD_ORDER_PRODUCT;
    payload: {
        selectedItems: IOrderProduct[],
    }
}
export const addSelectedItemsProps = (payload: IAddSelectedItemsAction['payload']): IAddSelectedItemsAction => {
    return {
        type: ADD_ORDER_PRODUCT,
        payload: payload
    }
};

export interface IRemoveSelectedItemsAction {
    readonly type: typeof REMOVE_PRODUCTS;
    payload: {
        selectedItems: IOrderProduct[],
    }
}
export const removeSelectedItemsProps = (payload: IRemoveSelectedItemsAction['payload']): IRemoveSelectedItemsAction => {
    return {
        type: REMOVE_PRODUCTS,
        payload: payload
    }
};

export interface IResetOrderProductsAction {
    readonly type: typeof RESET_ORDER_ITEMS;
    payload: {
        selectedItems: IOrderProduct[],
    }
}
export const resetOrderItemsProps = (payload: IResetOrderProductsAction['payload']): IResetOrderProductsAction => {
    return {
        type: RESET_ORDER_ITEMS,
        payload: payload
    }
};

export interface IUpdateIsLoadingProducts {
    readonly type: typeof UPDATE_IS_LOADING_PRODUCTS;
    payload: {
        isLoadingProducts: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingProductsProps = (
    payload: IUpdateIsLoadingProducts["payload"]
): IUpdateIsLoadingProducts => {
    return {
        type: UPDATE_IS_LOADING_PRODUCTS,
        payload: payload,
    };
};

export type ProductActions = ILoadProductsAction | IAddSelectedItemsAction | IRemoveSelectedItemsAction | IResetOrderProductsAction | IUpdateIsLoadingProducts
    | ILoadCommonProductsAction;