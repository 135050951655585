import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import { ILoadQuotationActionSaga, LOAD_QUOTATION_LIST_SAGA } from "./types"
import { quotationList, quotations } from '../../api/quotation';
import { loadQuotationProps, updateIsLoadingQuotationsProps } from '../../reducers/quotation/types';

function* loadQuotation(action: ILoadQuotationActionSaga) {
  try {
    yield put(updateIsLoadingQuotationsProps({ isLoadingQuotations: true, errorMessage: undefined }));
    const quotationList: {
      quotation_list: any[],
      total_count: number
    } = yield call(quotations, action.page, action.size, action.sort);
    yield put(updateIsLoadingQuotationsProps({ isLoadingQuotations: false, errorMessage: undefined }));
    yield put(loadQuotationProps({ quotationList: quotationList.quotation_list, totalQuotationCount: quotationList.total_count }))
  } catch (error: any) {
    yield put(updateIsLoadingQuotationsProps({ isLoadingQuotations: false, errorMessage: error.message }));
    yield put(displayErrorMessageProps({ message: error.message }))
  }
}
export function* watchLoadQuotation() {
  yield takeLatest(LOAD_QUOTATION_LIST_SAGA, loadQuotation)
}

export default [
  watchLoadQuotation(),
]
