import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_VENDORS_SAGA, ILoadVendorsActionSaga,
    RESET_VENDOR_SAGA,
    LOAD_VENDORS_RPODUCT_DETAILS_SAGA, ILoadVendorsProductDetailsActionSaga,
    LOAD_VENDOR_SAGA, ILoadVendorActionSaga,
    LOAD_VENDOR_PRODUCTS_QTYS_SAGA, ILoadVendorProductsQtysActionSaga,
    LOAD_VENDORS_PRODUCTS_SAGA, ILoadVendorsProductsActionSaga
} from "./types";
import { fetchVendors, fetchVendor, fetchVendorProducts } from "../../api/vendors";
import {
    loadVendorsProps, loadVendorsProductDetailsProps,
    loadVendorProps,
    loadVendorsProductsProps, loadVendorProductsQtysProps,
    updateIsLoadingVendorsQtysProps, updateIsLoadingVendorsProps
} from "../../reducers/vendors/types"
import IVendor from '../../dtos/IVendor';
import { fetchProductById } from '../../api/products';

function* loadVendorsData(action: ILoadVendorsActionSaga) {
    try {
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: true, errorMessage: undefined })
        );
        const vendors: { vendors: IVendor[], totalVendorsCount: number } = yield call(fetchVendors, action.page, action.size, action.sort, action.name);
        yield put(loadVendorsProps(vendors))
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: false, errorMessage: undefined })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadVendors() {
    yield takeLatest(LOAD_VENDORS_SAGA, loadVendorsData)
}

function* loadVendorsProductsData(action: ILoadVendorsProductsActionSaga) {
    try {
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: true, errorMessage: undefined })
        );
        const vendorsProducts: { vendorsProducts: any[], totalVendorProducts: number } = yield call(fetchVendorProducts, action.page, action.size, action.sort, action.vendorId, action.status, action.status, action.status, action.qty, action.qty, action.name, "",
            action.categories, action.variants, action.priceFrom, action.priceTo);
        yield put(loadVendorsProductsProps(vendorsProducts))
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: false, errorMessage: undefined })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadVendorsProducts() {
    yield takeLatest(LOAD_VENDORS_PRODUCTS_SAGA, loadVendorsProductsData)
}
async function loadvendorsProductDetails(vendorsProducts: any) {
    return await Promise.all(vendorsProducts.map(async (object: any) => {
        const productDetails: any = await fetchProductById(0, 10, object.product_id);
        if (productDetails) {
            return {
                ...object,
                product_sku: productDetails.sku,
                product_name: productDetails.name,
                product_images: productDetails.product_images,
                product_status: productDetails.status,
                variation_group: productDetails.variation_group,
                feature_values: productDetails.feature_values
            }
        }
        else {
            return {
                ...object,
                product_sku: '',
                product_name: '',
                product_images: '',
                variation_group: '',
                feature_values: ''
            }
        }
    })).then((res: any) => { return res; })
}
function* loadVendorsProductDetails(action: ILoadVendorsProductDetailsActionSaga) {
    try {
        yield put(updateIsLoadingVendorsQtysProps({ isLoadingVendorsQtys: true, errorMessage: undefined }));
        if (action.vendorsProducts.length > 0) {
            const vendorsProductDetails: any[] = yield call(loadvendorsProductDetails, action.vendorsProducts);
            yield put(loadVendorsProductDetailsProps({ vendorsProductDetails }));
            yield put(updateIsLoadingVendorsQtysProps({ isLoadingVendorsQtys: false, errorMessage: undefined }));
        }

    }
    catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingVendorsQtysProps({ isLoadingVendorsQtys: false, errorMessage: undefined }));
    }
}
export function* watchLoadVendorsProductDetails() {
    yield takeLatest(LOAD_VENDORS_RPODUCT_DETAILS_SAGA, loadVendorsProductDetails)
}
async function loadvendorProductsCounts(vendors: any) {
    return await Promise.all(vendors.map(async (object: any) => {
        const available: any = await fetchVendorProducts(0, 1, "", object.id, "A", "A", "A", 0, 0);
        const unavailable: any = await fetchVendorProducts(0, 1, "", object.id, "", "", "", 10, 10);
        return {
            ...object,
            availableQty: available.totalVendorProducts,
            unavailableQty: unavailable.totalVendorProducts - available.totalVendorProducts
        }
    })).then((res: any) => {
        return res;
    })
}
function* loadVendorProductsQtys(action: ILoadVendorProductsQtysActionSaga) {
    try {
        yield put(updateIsLoadingVendorsQtysProps({ isLoadingVendorsQtys: true, errorMessage: undefined }));
        if (action.vendors.length > 0) {
            const vendorProductsQtys: any[] = yield call(loadvendorProductsCounts, action.vendors);
            yield put(loadVendorProductsQtysProps({ vendorProductsQtys }))
            yield put(updateIsLoadingVendorsQtysProps({ isLoadingVendorsQtys: false, errorMessage: undefined }));
        }
    }
    catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingVendorsQtysProps({ isLoadingVendorsQtys: false, errorMessage: undefined }));
    }
}
export function* watchLoadVendorProductsQtys() {
    yield takeLatest(LOAD_VENDOR_PRODUCTS_QTYS_SAGA, loadVendorProductsQtys)
}
function* loadVendorData(action: ILoadVendorActionSaga) {
    try {
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: true, errorMessage: undefined }));
        const vendor: IVendor = yield call(fetchVendor, action.vendorId);
        yield put(loadVendorProps({ vendor: vendor }))
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: false, errorMessage: undefined }));
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadVendor() {
    yield takeLatest(LOAD_VENDOR_SAGA, loadVendorData)
}
function* resetVendorData(action: ILoadVendorActionSaga) {
    try {
        yield put(loadVendorProps({ vendor: null }))
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchResetVendorData() {
    yield takeLatest(RESET_VENDOR_SAGA, resetVendorData)
}
export default [
    watchLoadVendors(),
    watchLoadVendor(),
    watchLoadVendorProductsQtys(),
    watchLoadVendorsProducts(),
    watchLoadVendorsProductDetails(),
    watchResetVendorData()
]