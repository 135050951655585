import { ICamundaGroup, ICamundaTask, ICamundaUser } from "../../containers/Camunda/types";
import { LOAD_CUSTOMER_PROCESS, LOAD_CUSTOMER_TASKS, LOAD_GROUPS, LOAD_PROCESSES, LOAD_PROCESS_TASKS, LOAD_PROCESS_TASKS_SAGA, LOAD_TASKS, LOAD_TASK_CLAIMER, LOAD_USERS, UPDATE_IS_LOADING_CUSTOMERS, UPDATE_IS_LOADING_GROUPS, UPDATE_IS_LOADING_TASKS, UPDATE_IS_LOADING_USERS } from "../../sagas/camunda/types";
import { CamundaActions, IUpdateIsLoadingCustomers, IUpdateIsLoadingGroupsAction, IUpdateIsLoadingTasksAction, IUpdateIsLoadingUsersAction } from "./types"

interface ICamunda {
  tasks: ICamundaTask[],
  processes: any[],
  groups: ICamundaGroup[],
  users: ICamundaUser[],
  totalUsersCount: number,
  totalTasksCount: number,
  totalProcessesCount: number,
  totalGroupsCount: number,
  CustomerData: any[];
  loadingTasks: IUpdateIsLoadingTasksAction["payload"],
  loadingCustomers: IUpdateIsLoadingCustomers["payload"],
  loadingGroups: IUpdateIsLoadingGroupsAction["payload"],
  loadingUsers: IUpdateIsLoadingUsersAction["payload"],
}
const camundaState: ICamunda = {
  tasks: [],
  processes: [],
  groups: [],
  users: [],
  CustomerData: [],
  totalTasksCount: 0,
  totalUsersCount: 0,
  totalProcessesCount: 0,
  totalGroupsCount: 0,
  loadingCustomers: {
    isLoadingCustomers: false,
    errorMessage: undefined,
  },
  loadingTasks: {
    isLoadingTasks: false,
    errorMessage: undefined,
  },
  loadingGroups: {
    isLoadingGroups: false,
    errorMessage: undefined,
  },
  loadingUsers: {
    isLoadingUsers: false,
    errorMessage: undefined,
  }
}
const camundaRedcer = (
  state = camundaState,
  action: CamundaActions
): ICamunda => {
  switch (action.type) {
    case LOAD_TASKS:
      return {
        ...state,
        tasks: action.payload.tasks,
        totalTasksCount: action.payload.totalTasksCount,
      };
    case LOAD_USERS:
      return {
        ...state,
        users: action.payload.users,
        totalUsersCount: action.payload.totalUsersCount,
      };
    case LOAD_GROUPS:
      return {
        ...state,
        groups: action.payload.groups,
        totalGroupsCount: action.payload.totalGroupsCount,
      };
    case LOAD_CUSTOMER_TASKS:
      return {
        ...state,
        tasks: action.payload.CustomerData,
      }
    case LOAD_CUSTOMER_PROCESS:
      return {
        ...state,
        processes: action.payload.CustomerData,
      }
    case LOAD_PROCESSES:
      return {
        ...state,
        processes: action.payload.processes,
        totalProcessesCount: action.payload.totalProcessesCount,
      };
    case LOAD_PROCESS_TASKS:
      return {
        ...state,
        tasks: action.payload.tasks,
        totalTasksCount: action.payload.totalTasksCount,
      };
    case LOAD_TASK_CLAIMER:
      return {
        ...state,
        tasks: action.payload.ClaimerData,
      };
    case UPDATE_IS_LOADING_TASKS:
      return {
        ...state,
        loadingTasks: action.payload,
      };
    case UPDATE_IS_LOADING_CUSTOMERS:
      return {
        ...state,
        loadingCustomers: action.payload,
      };
    case UPDATE_IS_LOADING_GROUPS:
      return {
        ...state,
        loadingGroups: action.payload,
      };
    case UPDATE_IS_LOADING_USERS:
      return {
        ...state,
        loadingUsers: action.payload,
      };
    default:
      return state
  }
}
export default camundaRedcer;