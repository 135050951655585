import { put, takeLatest, call } from "redux-saga/effects";
import { displayErrorMessageProps } from "../../reducers/global/types";
import { ILoadOpenColdActionSaga, ILoadOpenHotActionSaga, ILoadOpenTotalActionSaga, ILoadOpenWarmActionSaga, ILoadPackedColdActionSaga, ILoadPackedHotActionSaga, ILoadPackedTotalActionSaga, ILoadPackedWarmActionSaga, ILoadReadyPickingColdActionSaga, ILoadReadyPickingHotActionSaga, ILoadReadyPickingTotalActionSaga, ILoadReadyPickingWarmActionSaga, ILoadShippedColdActionSaga, ILoadShippedHotActionSaga, ILoadShippedTotalActionSaga, ILoadShippedWarmActionSaga, LOAD_OPEN_COLD_SAGA, LOAD_OPEN_HOT_SAGA, LOAD_OPEN_TOTAL_SAGA, LOAD_OPEN_WARM_SAGA, LOAD_PACKED_COLD_SAGA, LOAD_PACKED_HOT_SAGA, LOAD_PACKED_TOTAL_SAGA, LOAD_PACKED_WARM_SAGA, LOAD_READY_PICKING_COLD_SAGA, LOAD_READY_PICKING_HOT_SAGA, LOAD_READY_PICKING_TOTAL_SAGA, LOAD_READY_PICKING_WARM_SAGA, LOAD_SHIPPED_COLD_SAGA, LOAD_SHIPPED_HOT_SAGA, LOAD_SHIPPED_TOTAL_SAGA, LOAD_SHIPPED_WARM_SAGA } from "./types";
import { loadOpenColdProps, loadOpenHotProps, loadOpenTotalProps, loadOpenWarmProps, loadPackedColdProps, loadPackedHotProps, loadPackedTotalProps, loadPackedWarmProps, loadReadyPickingColdProps, loadReadyPickingHotProps, loadReadyPickingTotalProps, loadReadyPickingWarmProps, loadShippedColdProps, loadShippedHotProps, loadShippedTotalProps, loadShippedWarmProps, updateIsLoadingSlaProps } from "../../reducers/sla/types";
import { fetchSla, fetchSlaOrders } from "../../api/sla";
import { IOrder } from "../../dtos/IOrder";

function* loadOpenTotalData(action: ILoadOpenTotalActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadOpenTotalProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenTotal() {
  yield takeLatest(LOAD_OPEN_TOTAL_SAGA, loadOpenTotalData);
}

function* loadOpenHotData(action: ILoadOpenHotActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadOpenHotProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenHot() {
  yield takeLatest(LOAD_OPEN_HOT_SAGA, loadOpenHotData);
}


function* loadOpenWarmData(action: ILoadOpenWarmActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadOpenWarmProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenWarm() {
  yield takeLatest(LOAD_OPEN_WARM_SAGA, loadOpenWarmData);
}

function* loadOpenColdData(action: ILoadOpenColdActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadOpenColdProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadOpenCold() {
  yield takeLatest(LOAD_OPEN_COLD_SAGA, loadOpenColdData);
}

function* loadReadyPickingTotalData(action: ILoadReadyPickingTotalActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadReadyPickingTotalProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadReadyPickingTotal() {
  yield takeLatest(LOAD_READY_PICKING_TOTAL_SAGA, loadReadyPickingTotalData);
}
function* loadReadyPickingHotData(action: ILoadReadyPickingHotActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadReadyPickingHotProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadReadyPickingHot() {
  yield takeLatest(LOAD_READY_PICKING_HOT_SAGA, loadReadyPickingHotData);
}

function* loadReadyPickingWarmData(action: ILoadReadyPickingWarmActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadReadyPickingWarmProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadReadyPickingWarm() {
  yield takeLatest(LOAD_READY_PICKING_WARM_SAGA, loadReadyPickingWarmData);
}

function* loadReadyPickingColdData(action: ILoadReadyPickingColdActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadReadyPickingColdProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadReadyPickingCold() {
  yield takeLatest(LOAD_READY_PICKING_COLD_SAGA, loadReadyPickingColdData);
}

function* loadPackedTotalData(action: ILoadPackedTotalActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadPackedTotalProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadPackedTotal() {
  yield takeLatest(LOAD_PACKED_TOTAL_SAGA, loadPackedTotalData);
}


function* loadPackedHotData(action: ILoadPackedHotActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadPackedHotProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadPackedHot() {
  yield takeLatest(LOAD_PACKED_HOT_SAGA, loadPackedHotData);
}
function* loadPackedWarmData(action: ILoadPackedWarmActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadPackedWarmProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadPackedWarm() {
  yield takeLatest(LOAD_PACKED_WARM_SAGA, loadPackedWarmData);
}
function* loadPackedColdData(action: ILoadPackedColdActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadPackedColdProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadPackedCold() {
  yield takeLatest(LOAD_PACKED_COLD_SAGA, loadPackedColdData);
}
function* loadShippedTotalData(action: ILoadShippedTotalActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadShippedTotalProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadShippedTotal() {
  yield takeLatest(LOAD_SHIPPED_TOTAL_SAGA, loadShippedTotalData);
}

function* loadShippedHotData(action: ILoadShippedHotActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadShippedHotProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadShippedHot() {
  yield takeLatest(LOAD_SHIPPED_HOT_SAGA, loadShippedHotData);
}
function* loadShippedWarmData(action: ILoadShippedWarmActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadShippedWarmProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadShippedWarm() {
  yield takeLatest(LOAD_SHIPPED_WARM_SAGA, loadShippedWarmData);
}
function* loadShippedColdData(action: ILoadShippedColdActionSaga) {
  try {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: true,
        errorMessage: undefined,
      })
    );
    const ordersData: {
      orders: any[];
      totalOrdersCount: number;
    } = yield call(
      fetchSla,
      action.fdcFlag,
      action.status,
      action.ergency,
      action.page,
      action.size,
      action.sort,
      action.orderId,
    );
    const orderIds: number[] = [];
    ordersData.orders.map((order) => {
      orderIds.push(parseInt(order.order_id));
    })
    const orderObjects: IOrder[] = yield call(fetchSlaOrders, orderIds);
    const constructedObjects: any[] = []
    ordersData.orders.map((slaOrder) => {
      let id = slaOrder.order_id;
      orderObjects.map((order) => {
        if (order.order_id == id) {
          constructedObjects.push({ ...slaOrder, ...order });
        }
      })
    })
    yield put(
      loadShippedColdProps({
        orders: constructedObjects,
        totalOrdersCount: ordersData.totalOrdersCount,
      })
    );
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: undefined,
      })
    );
  } catch (error: any) {
    yield put(
      updateIsLoadingSlaProps({
        isLoadingSla: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadShippedCold() {
  yield takeLatest(LOAD_SHIPPED_COLD_SAGA, loadShippedColdData);
}


export default [
  watchLoadOpenTotal(),
  watchLoadOpenHot(),
  watchLoadOpenWarm(),
  watchLoadOpenCold(),

  watchLoadReadyPickingTotal(),
  watchLoadReadyPickingHot(),
  watchLoadReadyPickingWarm(),
  watchLoadReadyPickingCold(),

  watchLoadPackedTotal(),
  watchLoadPackedHot(),
  watchLoadPackedWarm(),
  watchLoadPackedCold(),

  watchLoadShippedTotal(),
  watchLoadShippedHot(),
  watchLoadShippedWarm(),
  watchLoadShippedCold(),
];
