import {
  LOAD_OPEN_COLD,
  LOAD_OPEN_HOT,
  LOAD_OPEN_TOTAL,
  LOAD_OPEN_WARM,
  LOAD_PACKED_COLD,
  LOAD_PACKED_HOT,
  LOAD_PACKED_TOTAL,
  LOAD_PACKED_WARM,
  LOAD_READY_PICKING_COLD,
  LOAD_READY_PICKING_HOT,
  LOAD_READY_PICKING_TOTAL,
  LOAD_READY_PICKING_WARM,
  LOAD_SHIPPED_COLD,
  LOAD_SHIPPED_HOT,
  LOAD_SHIPPED_TOTAL,
  LOAD_SHIPPED_WARM,
  UPDATE_IS_LOADING_SLA,
} from "../../sagas/sla/types";

import { IOrder } from "../../dtos/IOrder";

export interface ILoadOpenTotalAction {
  readonly type: typeof LOAD_OPEN_TOTAL;
  payload: {
    orders: any[];
    totalOrdersCount: number;
  };
}
export const loadOpenTotalProps = (
  payload: ILoadOpenTotalAction["payload"]
): ILoadOpenTotalAction => {
  return {
    type: LOAD_OPEN_TOTAL,
    payload: payload,
  };
};

export interface ILoadOpenHotAction {
  readonly type: typeof LOAD_OPEN_HOT;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadOpenHotProps = (
  payload: ILoadOpenHotAction["payload"]
): ILoadOpenHotAction => {
  return {
    type: LOAD_OPEN_HOT,
    payload: payload,
  };
};


export interface ILoadOpenWarmAction {
  readonly type: typeof LOAD_OPEN_WARM;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadOpenWarmProps = (
  payload: ILoadOpenWarmAction["payload"]
): ILoadOpenWarmAction => {
  return {
    type: LOAD_OPEN_WARM,
    payload: payload,
  };
};

export interface ILoadOpenColdAction {
  readonly type: typeof LOAD_OPEN_COLD;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadOpenColdProps = (
  payload: ILoadOpenColdAction["payload"]
): ILoadOpenColdAction => {
  return {
    type: LOAD_OPEN_COLD,
    payload: payload,
  };
};

export interface ILoadReadyPickingTotalAction {
  readonly type: typeof LOAD_READY_PICKING_TOTAL;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadReadyPickingTotalProps = (
  payload: ILoadReadyPickingTotalAction["payload"]
): ILoadReadyPickingTotalAction => {
  return {
    type: LOAD_READY_PICKING_TOTAL,
    payload: payload,
  };
};

export interface ILoadReadyPickingHotAction {
  readonly type: typeof LOAD_READY_PICKING_HOT;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadReadyPickingHotProps = (
  payload: ILoadReadyPickingHotAction["payload"]
): ILoadReadyPickingHotAction => {
  return {
    type: LOAD_READY_PICKING_HOT,
    payload: payload,
  };
};
export interface ILoadReadyPickingWarmAction {
  readonly type: typeof LOAD_READY_PICKING_WARM;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadReadyPickingWarmProps = (
  payload: ILoadReadyPickingWarmAction["payload"]
): ILoadReadyPickingWarmAction => {
  return {
    type: LOAD_READY_PICKING_WARM,
    payload: payload,
  };
};
export interface ILoadReadyPickingColdAction {
  readonly type: typeof LOAD_READY_PICKING_COLD;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadReadyPickingColdProps = (
  payload: ILoadReadyPickingColdAction["payload"]
): ILoadReadyPickingColdAction => {
  return {
    type: LOAD_READY_PICKING_COLD,
    payload: payload,
  };
};

export interface ILoadPackedTotalAction {
  readonly type: typeof LOAD_PACKED_TOTAL;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadPackedTotalProps = (
  payload: ILoadPackedTotalAction["payload"]
): ILoadPackedTotalAction => {
  return {
    type: LOAD_PACKED_TOTAL,
    payload: payload,
  };
};
export interface ILoadPackedHotAction {
  readonly type: typeof LOAD_PACKED_HOT;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadPackedHotProps = (
  payload: ILoadPackedHotAction["payload"]
): ILoadPackedHotAction => {
  return {
    type: LOAD_PACKED_HOT,
    payload: payload,
  };
};
export interface ILoadPackedWarmAction {
  readonly type: typeof LOAD_PACKED_WARM;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadPackedWarmProps = (
  payload: ILoadPackedWarmAction["payload"]
): ILoadPackedWarmAction => {
  return {
    type: LOAD_PACKED_WARM,
    payload: payload,
  };
};
export interface ILoadPackedColdAction {
  readonly type: typeof LOAD_PACKED_COLD;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadPackedColdProps = (
  payload: ILoadPackedColdAction["payload"]
): ILoadPackedColdAction => {
  return {
    type: LOAD_PACKED_COLD,
    payload: payload,
  };
};

export interface ILoadShippedTotalAction {
  readonly type: typeof LOAD_SHIPPED_TOTAL;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadShippedTotalProps = (
  payload: ILoadShippedTotalAction["payload"]
): ILoadShippedTotalAction => {
  return {
    type: LOAD_SHIPPED_TOTAL,
    payload: payload,
  };
};
export interface ILoadShippedHotAction {
  readonly type: typeof LOAD_SHIPPED_HOT;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadShippedHotProps = (
  payload: ILoadShippedHotAction["payload"]
): ILoadShippedHotAction => {
  return {
    type: LOAD_SHIPPED_HOT,
    payload: payload,
  };
};

export interface ILoadShippedWarmAction {
  readonly type: typeof LOAD_SHIPPED_WARM;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadShippedWarmProps = (
  payload: ILoadShippedWarmAction["payload"]
): ILoadShippedWarmAction => {
  return {
    type: LOAD_SHIPPED_WARM,
    payload: payload,
  };
};

export interface ILoadShippedColdAction {
  readonly type: typeof LOAD_SHIPPED_COLD;
  payload: {
    orders: IOrder[];
    totalOrdersCount: number;
  };
}
export const loadShippedColdProps = (
  payload: ILoadShippedColdAction["payload"]
): ILoadShippedColdAction => {
  return {
    type: LOAD_SHIPPED_COLD,
    payload: payload,
  };
};











export interface IUpdateIsLoadingSlaAction {
  readonly type: typeof UPDATE_IS_LOADING_SLA;
  payload: {
    isLoadingSla: boolean;
    errorMessage: string | undefined;
  };
}
export const updateIsLoadingSlaProps = (
  payload: IUpdateIsLoadingSlaAction["payload"]
): IUpdateIsLoadingSlaAction => {
  return {
    type: UPDATE_IS_LOADING_SLA,
    payload: payload,
  };
};

export type slaActions =
  | ILoadOpenTotalAction
  | ILoadOpenHotAction
  | ILoadOpenWarmAction
  | ILoadOpenColdAction
  | ILoadReadyPickingTotalAction
  | ILoadReadyPickingHotAction
  | ILoadReadyPickingWarmAction
  | ILoadReadyPickingColdAction
  | ILoadPackedTotalAction
  | ILoadPackedHotAction
  | ILoadPackedWarmAction
  | ILoadPackedColdAction
  | ILoadShippedTotalAction
  | ILoadShippedHotAction
  | ILoadShippedWarmAction
  | ILoadShippedColdAction
  | IUpdateIsLoadingSlaAction;
