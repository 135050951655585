import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_BRANDS_SAGA, ILoadBrandsActionSaga,
    LOAD_BRAND_SAGA, ILoadBrandActionSaga,
    RESET_BRAND_SAGA,
    LOAD_BRAND_PRODUCTS_QTYS_SAGA, ILoadBrandProductsQtysActionSaga
} from "./types";
import { fetchAllBrands, fetchBrand } from "../../api/brands";
import {
    loadBrandsProps,
    loadBrandProps, loadBrandProductsQtysProps,
    updateIsLoadingBrandsQtysProps, updateIsLoadingBrandsProps
} from "../../reducers/brands/types"

function* loadBrandsData(action: ILoadBrandsActionSaga) {
    try {
        yield put(updateIsLoadingBrandsProps({ isLoadingBrands: true, errorMessage: undefined })
        );
        const brands: { brands: any[], totalBrandsCount: number } = yield call(fetchAllBrands, action.page, action.sort, action.size);
        yield put(loadBrandsProps(brands))
        yield put(updateIsLoadingBrandsProps({ isLoadingBrands: false, errorMessage: undefined })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingBrandsProps({ isLoadingBrands: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadBrands() {
    yield takeLatest(LOAD_BRANDS_SAGA, loadBrandsData)
}



function* loadBrandProductsQtys(action: ILoadBrandProductsQtysActionSaga) {
    try {
        yield put(updateIsLoadingBrandsQtysProps({ isLoadingBrandsQtys: true, errorMessage: undefined }));
        if (action.brands.length > 0) {
            const brandProductsQtys: any[] = [];
            // yield call(loadvendorProductsCounts, action.vendors);
            yield put(loadBrandProductsQtysProps({ brandProductsQtys }))
            yield put(updateIsLoadingBrandsQtysProps({ isLoadingBrandsQtys: false, errorMessage: undefined }));
        }
    }
    catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingBrandsQtysProps({ isLoadingBrandsQtys: false, errorMessage: undefined }));
    }
}
export function* watchLoadBrandProductsQtys() {
    yield takeLatest(LOAD_BRAND_PRODUCTS_QTYS_SAGA, loadBrandProductsQtys)
}
function* loadBrandData(action: ILoadBrandActionSaga) {
    try {
        yield put(updateIsLoadingBrandsProps({ isLoadingBrands: true, errorMessage: undefined }));
        const brand: any = yield call(fetchBrand, action.brandId);
        yield put(loadBrandProps({ brand: brand }))
        yield put(updateIsLoadingBrandsProps({ isLoadingBrands: false, errorMessage: undefined }));
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingBrandsProps({ isLoadingBrands: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadBrand() {
    yield takeLatest(LOAD_BRAND_SAGA, loadBrandData)
}
function* resetBrandData(action: ILoadBrandActionSaga) {
    try {
        yield put(loadBrandProps({ brand: null }))
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchResetBrandData() {
    yield takeLatest(RESET_BRAND_SAGA, resetBrandData)
}
export default [
    watchLoadBrands(),
    watchLoadBrand(),
    watchLoadBrandProductsQtys(),
    watchResetBrandData()
]