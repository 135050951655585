import { call, put, takeLatest } from "redux-saga/effects";
import { fetchProcesses, fetchProcessTasks } from "../../api/camunda/process";
import { fetchTasks, getUsersInGroup } from "../../api/camunda/task";
import { getGroups, getusers } from "../../api/camunda/users";
import { getUser } from "../../api/users";
import { ICamundaGroup, ICamundaTask, ICamundaUser } from "../../containers/Camunda/types";
import { loadCustomerProcessProps, loadCustomerTasksProps, loadGroupProps, loadProcessesProps, loadProcessTasksProps, loadTaskClaimerProps, loadTasksProps, loadUserProps, updateIsLoadingCustomerProps, updateIsLoadingGroupsProps, updateIsLoadingTasksProps, updateIsLoadingUsersProps } from "../../reducers/camunda/types";
import { displayErrorMessageProps } from "../../reducers/global/types";
import { ILoadGroupssActionSaga, ILoadProcessesActionSaga, ILoadProcessTasksActionSaga, ILoadTasksActionSaga, ILoadUsersActionSaga, LOAD_CUSTOMER_PROCESS_SAGA, LOAD_CUSTOMER_TASKS_SAGA, LOAD_GROUPS_SAGA, LOAD_PROCESSES_SAGA, LOAD_PROCESS_TASKS_SAGA, LOAD_TASKS_SAGA, LOAD_TASK_CLAIMER_SAGA, LOAD_USERS_SAGA } from "./types";


function* loadUsersData(action: ILoadUsersActionSaga) {
  try {
    yield put(updateIsLoadingUsersProps({ isLoadingUsers: true, errorMessage: undefined }));
    const usersData: { users: ICamundaUser[]; totalUsersCount: number; } = yield call(getusers, action.subject_id, action.group_name, action.by_user_group, action.page, action.viewBy);
    yield put(loadUserProps({ users: usersData.users, totalUsersCount: usersData.totalUsersCount }));
    yield put(updateIsLoadingUsersProps({ isLoadingUsers: false, errorMessage: undefined }));
  } catch (error: any) {
    yield put(updateIsLoadingUsersProps({ isLoadingUsers: false, errorMessage: error.message }));
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadUsers() {
  yield takeLatest(LOAD_USERS_SAGA, loadUsersData);
}

function* loadGroupsData(action: ILoadGroupssActionSaga) {
  try {
    yield put(updateIsLoadingGroupsProps({ isLoadingGroups: true, errorMessage: undefined }));
    const groupssData: { groups: ICamundaGroup[]; totalGroupsCount: number; } = yield call(getGroups, action.subject_id);
    yield put(loadGroupProps({ groups: groupssData.groups, totalGroupsCount: groupssData.totalGroupsCount }));
    yield put(updateIsLoadingGroupsProps({ isLoadingGroups: false, errorMessage: undefined }));
  } catch (error: any) {
    yield put(updateIsLoadingGroupsProps({ isLoadingGroups: false, errorMessage: error.message }));
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadGroups() {
  yield takeLatest(LOAD_GROUPS_SAGA, loadGroupsData);
}

async function loadCustomerData(tasks: any, subject_id?: string) {
  return await Promise.all(tasks.map(async (object: any) => {
    let constructedObj = {};
    if (object.variables.subject_id == undefined && object.variables.requestCreator == undefined) {
      return { ...object }
    }
    if (object.variables.subject_id) {
      const customer_data: any = await getUser(object.variables.subject_id);
      constructedObj = { ...constructedObj, customer_data: customer_data }
    }
    if (object.variables.requestCreator != "null") {
      const requestor_data: any = await getUser(object.variables.requestCreator);
      constructedObj = { ...constructedObj, requestor_data: requestor_data }
    }
    if (subject_id) {
      const list_users: any = await getUsersInGroup(subject_id as string);
      constructedObj = { ...constructedObj, list_users: list_users }
    }
    return { ...object, ...constructedObj }
  })).then((res: any) => {
    return res;
  })
}

async function loadTaskClaimer(tasks: any) {
  return await Promise.all(tasks.map(async (object: any) => {
    if (object.assignee == undefined) {
      return { ...object }
    } else {
      const task_claimer: any = await getUser(object.assignee);
      return { ...object, task_claimer: task_claimer }
    }
  })).then((res: any) => {
    return res;
  })
}

function* loadTasksCalimer(action: any) {
  try {
    yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: true, errorMessage: undefined }));
    if (action.tasks.length > 0) {
      const ClaimerData: any[] = yield call(loadTaskClaimer, action.tasks);
      yield put(loadTaskClaimerProps({ ClaimerData }))
      yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: false, errorMessage: undefined }));
    }
  }
  catch (error: any) {
    yield put(displayErrorMessageProps({ message: error.message }))
    yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: false, errorMessage: undefined }));
  }
}
export function* watchTaskClaimer() {
  yield takeLatest(LOAD_TASK_CLAIMER_SAGA, loadTasksCalimer)
}

function* loadCustomerTasks(action: any) {
  try {
    yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: true, errorMessage: undefined }));
    if (action.tasks.length > 0) {
      const CustomerData: any[] = yield call(loadCustomerData, action.tasks, action.subject_id);
      yield put(loadCustomerTasksProps({ CustomerData }))
      yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: false, errorMessage: undefined }));
    }
  }
  catch (error: any) {
    yield put(displayErrorMessageProps({ message: error.message }))
    yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: false, errorMessage: undefined }));
  }
}
export function* watchLoadCustomerTasks() {
  yield takeLatest(LOAD_CUSTOMER_TASKS_SAGA, loadCustomerTasks)
}

function* loadCustomerProcess(action: any) {
  try {
    yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: true, errorMessage: undefined }));
    if (action.processes.length > 0) {
      const CustomerData: any[] = yield call(loadCustomerData, action.processes);
      yield put(loadCustomerProcessProps({ CustomerData }))
      yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: false, errorMessage: undefined }));
    }
  }
  catch (error: any) {
    yield put(displayErrorMessageProps({ message: error.message }))
    yield put(updateIsLoadingCustomerProps({ isLoadingCustomers: false, errorMessage: undefined }));
  }
}
export function* watchLoadCustomerProcess() {
  yield takeLatest(LOAD_CUSTOMER_PROCESS_SAGA, loadCustomerProcess)
}


function* loadTasksData(action: ILoadTasksActionSaga) {
  try {
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: true, errorMessage: undefined }));
    const tasksData: {
      tasks: ICamundaTask[];
      totaltasksCount: number;
    } = yield call(
      fetchTasks,
      action.include_assigned,
      action.my_task,
      action.overdue,
      action.subject_id,
      action.process_id,
      action.task_id,
      action.process_list,
      action.task_list,
      action.variable_name,
      action.variable_value,
      action.page,
      action.size,
      action.sort,
    );
    yield put(loadTasksProps({ tasks: tasksData.tasks, totalTasksCount: tasksData.totaltasksCount }));
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: false, errorMessage: undefined }));
  } catch (error: any) {
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: false, errorMessage: error.message }));
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadTasks() {
  yield takeLatest(LOAD_TASKS_SAGA, loadTasksData);
}


function* loadProcessTasksData(action: ILoadProcessTasksActionSaga) {
  try {
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: true, errorMessage: undefined }));
    const processTasksData: {
      tasks: ICamundaTask[];
      totaltasksCount: number;
    } = yield call(fetchProcessTasks, action.process_id, action.subject_id);
    yield put(loadProcessTasksProps({ tasks: processTasksData.tasks, totalTasksCount: processTasksData.totaltasksCount }));
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: false, errorMessage: undefined }));
  } catch (error: any) {
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: false, errorMessage: error.message }));
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadProcessTasks() {
  yield takeLatest(LOAD_PROCESS_TASKS_SAGA, loadProcessTasksData);
}

function* loadProcessesData(action: ILoadProcessesActionSaga) {
  try {
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: true, errorMessage: undefined }));
    const processesData: {
      processes: ICamundaTask[];
      totalProcessesCount: number;
    } = yield call(
      fetchProcesses,
      action.subject_id,
      action.process_id,
      action.process_list,
      action.variable_name,
      action.variable_value,
      action.page,
      action.size,
      action.sort,
    );
    yield put(loadProcessesProps({ processes: processesData.processes, totalProcessesCount: processesData.totalProcessesCount }));
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: false, errorMessage: undefined }));
  } catch (error: any) {
    yield put(updateIsLoadingTasksProps({ isLoadingTasks: false, errorMessage: error.message }));
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadProcess() {
  yield takeLatest(LOAD_PROCESSES_SAGA, loadProcessesData);
}

export default [
  watchLoadTasks(),
  watchLoadProcess(),
  watchLoadProcessTasks(),
  watchLoadCustomerTasks(),
  watchLoadCustomerProcess(),
  watchTaskClaimer(),
  watchLoadGroups(),
  watchLoadUsers(),
];