import { IUpdateIsLoadingSlaAction, slaActions } from "./types";
import {
  LOAD_OPEN_COLD,
  LOAD_OPEN_HOT,
  LOAD_OPEN_TOTAL,
  LOAD_OPEN_WARM,
  LOAD_PACKED_COLD,
  LOAD_PACKED_HOT,
  LOAD_PACKED_TOTAL,
  LOAD_PACKED_WARM,
  LOAD_READY_PICKING_COLD,
  LOAD_READY_PICKING_HOT,
  LOAD_READY_PICKING_TOTAL,
  LOAD_READY_PICKING_WARM,
  LOAD_SHIPPED_COLD,
  LOAD_SHIPPED_HOT,
  LOAD_SHIPPED_TOTAL,
  LOAD_SHIPPED_WARM,
  UPDATE_IS_LOADING_SLA,
} from "../../sagas/sla/types";

interface ISlaStatus {
  slaOrders: any[];
  slaOrdersCount: number;

  totalOpenTotal: number;
  totalOpenHot: number;
  totalOpenWarm: number;
  totalOpenCold: number;

  totalReadyPickingTotal: number;
  totalReadyPickingHot: number;
  totalReadyPickingWarm: number;
  totalReadyPickingCold: number;

  totalPackedTotal: number;
  totalPackedHot: number;
  totalPackedWarm: number;
  totalPackedCold: number;

  totalShippedTotal: number;
  totalShippedHot: number;
  totalShippedWarm: number;
  totalShippedCold: number;

  loadingSla: IUpdateIsLoadingSlaAction["payload"];
}
const SlaStatus: ISlaStatus = {
  slaOrders: [],
  slaOrdersCount: 0,

  totalOpenTotal: 0,
  totalOpenHot: 0,
  totalOpenWarm: 0,
  totalOpenCold: 0,

  totalReadyPickingTotal: 0,
  totalReadyPickingHot: 0,
  totalReadyPickingWarm: 0,
  totalReadyPickingCold: 0,

  totalPackedTotal: 0,
  totalPackedHot: 0,
  totalPackedWarm: 0,
  totalPackedCold: 0,

  totalShippedTotal: 0,
  totalShippedHot: 0,
  totalShippedWarm: 0,
  totalShippedCold: 0,

  loadingSla: {
    isLoadingSla: false,
    errorMessage: undefined,
  },
};

const slaReducer = (
  state = SlaStatus,
  action: slaActions
): ISlaStatus => {
  switch (action.type) {
    case LOAD_OPEN_TOTAL:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalOpenTotal: action.payload.totalOrdersCount,
      };
    case LOAD_OPEN_HOT:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalOpenHot: action.payload.totalOrdersCount,
      };
    case LOAD_OPEN_WARM:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalOpenWarm: action.payload.totalOrdersCount,
      };
    case LOAD_OPEN_COLD:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalOpenCold: action.payload.totalOrdersCount,
      };
    case LOAD_READY_PICKING_TOTAL:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalReadyPickingTotal: action.payload.totalOrdersCount,
      };
    case LOAD_READY_PICKING_HOT:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalReadyPickingHot: action.payload.totalOrdersCount,
      };
    case LOAD_READY_PICKING_WARM:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalReadyPickingWarm: action.payload.totalOrdersCount,
      };
    case LOAD_READY_PICKING_COLD:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalReadyPickingCold: action.payload.totalOrdersCount,
      };
    case LOAD_PACKED_TOTAL:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalPackedTotal: action.payload.totalOrdersCount,
      };
    case LOAD_PACKED_HOT:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalPackedHot: action.payload.totalOrdersCount,
      };
    case LOAD_PACKED_WARM:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalPackedWarm: action.payload.totalOrdersCount,
      };
    case LOAD_PACKED_COLD:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalPackedCold: action.payload.totalOrdersCount,
      };
    case LOAD_SHIPPED_TOTAL:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalShippedTotal: action.payload.totalOrdersCount,
      };
    case LOAD_SHIPPED_HOT:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalShippedHot: action.payload.totalOrdersCount,
      };
    case LOAD_SHIPPED_WARM:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalShippedWarm: action.payload.totalOrdersCount,
      };
    case LOAD_SHIPPED_COLD:
      return {
        ...state,
        slaOrders: action.payload.orders,
        slaOrdersCount: action.payload.totalOrdersCount,
        totalShippedCold: action.payload.totalOrdersCount,
      };
    case UPDATE_IS_LOADING_SLA:
      return {
        ...state,
        loadingSla: action.payload,
      };
    default:
      return state;
  }
};
export default slaReducer;
