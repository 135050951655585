import { fetchList } from "../../api/orders";
import { fetchSales } from "../../api/users";

export default class Sales {
  static instance;
  list;
  constructor() {
    this.list = [];
    fetchSales().then((res) => {
      let formattedItems = [];
      res.map((item, index) => {
        formattedItems.push({ label: item.name, value: item.name });
      });
      this.list = formattedItems;
    });
  }
  static getInstance() {
    if (!Sales.instance) {
      Sales.instance = new Sales();
    }
    return Sales.instance;
  }
  getList() {
    return this.list;
  }
}
