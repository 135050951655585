import {
    LOAD_BRANDS, LOAD_BRAND_PRODUCTS_QTYS, LOAD_BRAND,
    UPDATE_IS_LOADING_BRANDS_QTYS, UPDATE_IS_LOADING_BRANDS
} from "../../sagas/brands/types";


export interface ILoadBrandsAction {
    readonly type: typeof LOAD_BRANDS;
    payload: {
        brands: any[],
        totalBrandsCount: number
    }
}
export const loadBrandsProps = (payload: ILoadBrandsAction['payload']): ILoadBrandsAction => {
    return {
        type: LOAD_BRANDS,
        payload: payload
    }
};

export interface ILoadBrandProductsQtysAction {
    readonly type: typeof LOAD_BRAND_PRODUCTS_QTYS;
    payload: {
        brandProductsQtys: any[],
    }
}
export const loadBrandProductsQtysProps = (payload: ILoadBrandProductsQtysAction['payload']): ILoadBrandProductsQtysAction => {
    return {
        type: LOAD_BRAND_PRODUCTS_QTYS,
        payload: payload
    }
};

export interface ILoadBrandAction {
    readonly type: typeof LOAD_BRAND;
    payload: {
        brand: any | null,
    }
}
export const loadBrandProps = (payload: ILoadBrandAction['payload']): ILoadBrandAction => {
    return {
        type: LOAD_BRAND,
        payload: payload
    }
};

export interface IUpdateIsLoadingBrands {
    readonly type: typeof UPDATE_IS_LOADING_BRANDS;
    payload: {
        isLoadingBrands: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingBrandsProps = (
    payload: IUpdateIsLoadingBrands["payload"]
): IUpdateIsLoadingBrands => {
    return {
        type: UPDATE_IS_LOADING_BRANDS,
        payload: payload,
    };
};
export interface IUpdateIsLoadingBrandsQtys {
    readonly type: typeof UPDATE_IS_LOADING_BRANDS_QTYS;
    payload: {
        isLoadingBrandsQtys: boolean;
        errorMessage: string | undefined;
    };
}
export const updateIsLoadingBrandsQtysProps = (
    payload: IUpdateIsLoadingBrandsQtys["payload"]
): IUpdateIsLoadingBrandsQtys => {
    return {
        type: UPDATE_IS_LOADING_BRANDS_QTYS,
        payload: payload,
    };
};

export type BrandsActions = ILoadBrandsAction | ILoadBrandProductsQtysAction | ILoadBrandAction
    | IUpdateIsLoadingBrandsQtys | IUpdateIsLoadingBrands;